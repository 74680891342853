import { useState } from "react";
import { Flex } from "rebass";

import { TMatchHistory, TSteamFriend } from "../@types";
import {
  Dropdown,
  DropdownTopSection,
  StyledButtonContainer,
  DropdownContainer,
  AvatarRow,
} from "../../MatchHistory/styledComponents";
import { Avatar } from "../../../Components/Avatar";
import { Subheader } from "../../../Components/Text";
import { DividerAlt } from "../../../Components/Divider";
import SteamIcon from "../../../Assets/Platforms/steam.svg";
import AllstarIcon from "../../../Assets/Brand/A-Mark-Light.svg";
import { ReactComponent as HistoryLogo } from "../../../../MVVM/Assets/Nav/nav-history.svg";
import styled from "styled-components";
import { URLS } from "../../../Utilities/Maps";

export const MatchHistorySteamFriendsRow = (props: {
  size: string;
  match: TMatchHistory;
  steamFriends: TSteamFriend[];
}) => {
  const [showFriendDropdown, setShowFriendDropdown] = useState("");

  const getFriendsInMatch = (): TSteamFriend[] => {
    if (
      !Array.isArray(props?.steamFriends) ||
      !Array.isArray(props?.match?.players)
    ) {
      return [];
    }

    const result = props.steamFriends?.filter((steamFriend) =>
      props.match.players.some(
        (matchPlayer) => matchPlayer.steamId === steamFriend.steamId64,
      ),
    );
    return result;
  };

  return (
    <AvatarRow>
      {getFriendsInMatch().map((friend) => (
        <Flex
          onMouseEnter={() => setShowFriendDropdown(friend.steamId64)}
          onMouseLeave={() => setShowFriendDropdown("")}
          key={friend.steamId64}
          style={{ position: "relative" }}
        >
          <Avatar
            size={props.size ? props.size : "default"}
            image={friend.avatarUrl}
          />
          {showFriendDropdown === friend.steamId64 && (
            <MatchHistorySteamFriendDropdown
              steamFriends={props.steamFriends}
              steamId={friend.steamId64}
              username={friend.displayName}
            />
          )}
        </Flex>
      ))}
    </AvatarRow>
  );
};

export const MatchHistorySteamFriendDropdown = (props: {
  steamFriends: TSteamFriend[];
  steamId: string;
  username?: string;
}) => {
  const steamFriend = props.steamFriends?.find(
    (friend) => friend.steamId64 === props.steamId,
  );
  const displayName = steamFriend?.displayName || props.username || "";

  return (
    <DropdownContainer>
      <Dropdown>
        <DropdownTopSection>
          <Flex width="100%">
            <StyledButtonContainer
              to={`${URLS.MATCH_HISTORY}?playerSearchIdentifier=${props.steamId}#counter-strike-2`}
              onClick={() => {
                window.rudderanalytics.track("Match History - Visit Other");
              }}
            >
              <StyledHistoryLogo
                style={{
                  height: "20px",
                  width: "20px",
                  marginRight: "5px",
                }}
              />

              <Subheader>Go to Match History</Subheader>
            </StyledButtonContainer>
          </Flex>
          <DividerAlt width="90%" my="5px" />

          <Flex width="100%">
            <StyledButtonContainer
              target=""
              to="#"
              onClick={() => {
                window.open(
                  "http://steamcommunity.com/profiles/" + props.steamId,
                  "_blank",
                );
              }}
            >
              <img
                width={20}
                height={20}
                src={SteamIcon}
                style={{ marginRight: 5 }}
              />
              <Subheader>{displayName}</Subheader>
            </StyledButtonContainer>
          </Flex>
          {
            // Does Allstar user exist?
            steamFriend?.user && steamFriend?.user?.length > 0 && (
              <>
                <DividerAlt width="90%" my="5px" />
                <Flex width="100%">
                  <StyledButtonContainer
                    target=""
                    to="#"
                    onClick={() => {
                      window.open(
                        "/profile?user=" + steamFriend?.user,
                        "_blank",
                      );
                    }}
                  >
                    <img
                      width={20}
                      height={20}
                      src={AllstarIcon}
                      style={{ marginRight: 5 }}
                    />
                    <Subheader>{displayName}</Subheader>
                  </StyledButtonContainer>
                </Flex>
              </>
            )
          }
        </DropdownTopSection>
      </Dropdown>
    </DropdownContainer>
  );
};
const StyledHistoryLogo = styled(HistoryLogo)`
  fill: ${(props) => props.theme.colors.envy};
`;
