import { CHECK_TRANSIT_TIME } from "../../GraphQL/transitTIme";
import { ETransitStatusTypes } from "./@types";
import { useQuery } from "@apollo/client";
export type TTtransitTime = {
  approxTimeToComplete: number;
  localKey: ETransitStatusTypes;
  isMobile: boolean;
  clip: {
    clipTitle: string;
    game: number;
    trackId: string;
  };
};

type TTransitTimeViewModelProps = {
  user?: string;
  clip?: string;
  spectating?: Record<string,any>;
};

export const TransitTimeViewModel = ({
  user,
  clip,
  spectating,
}: TTransitTimeViewModelProps) => {
  const { data, error, loading, refetch } = useQuery(CHECK_TRANSIT_TIME, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    skip: !user && !clip,
    variables: {
      type: user ? "user" : "clip",
      user: user,
      clipIdentifier: clip,
    },
  });

  if (!spectating) {
    return {
      transitData: {
        myTransitTimes: (data?.transitTimeCheck as TTtransitTime[]) || [],
      },
      transitError: error,
      transitLoading: loading,
      refetch,
    };
  } else {
    return {
      transitData: {
        myTransitTimes: ([spectating] as TTtransitTime[]) || [],
      },
    };
  }
};
