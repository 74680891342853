import axios, { AxiosResponse } from "axios";
import { Buffer } from "buffer";

const BASE_URL = process.env.REACT_APP_API_URL;

export class User {
  public UserObjectId: string | null = null;
  public TokenExpires: number = 0;
  private static instance: User;

  public static getInstance(): User {
    if (!User.instance) User.instance = new User();
    return User.instance;
  }

  public async AllstarProfileUpdate(patchBody: {
    username: string;
    countryCode: string;
    email?: string;
  }): Promise<AxiosResponse> {
    try {
      const response = await axios.patch(
        `${BASE_URL}/items/profile/@me?token=${get("token")}`,
        patchBody,
      );
      return response;
    } catch (error: any) {
      return error;
    }
  }

  public async AllstarClips(
    userId: string,
  ): Promise<{ clips: any; lastRun: number }> {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/items/clips?user=${userId}&token=${get("token")}`,
      );
      return { clips: data, lastRun: Date.now() };
    } catch (error: any) {
      return { clips: null, lastRun: Date.now() };
    }
  }

  public async AllstarProcessingClips(
    userId: string,
    game: 730 | 101 | 570 | 407,
  ): Promise<{ clips: any; lastRun: number }> {
    try {
      const { data } = await axios.get(
        `${BASE_URL}/items/clips?processing=true&game=${game}&user=${userId}`,
      );
      return {
        clips: data?.processingClips,
        lastRun: Date.now(),
      };
    } catch (error: any) {
      return { clips: null, lastRun: Date.now() };
    }
  }

  public async AddSteamAccount(steamId: string): Promise<boolean> {
    try {
      await axios.post(`${BASE_URL}/users/steam_id?token=${get("token")}`, {
        steamId64: steamId,
      });
      return true;
    } catch (e) {
      return false;
    }
  }

  public async TestMatchToken(
    steamId: string,
    authCode: string,
    shareCode: string,
  ): Promise<boolean> {
    try {
      await axios.get(
        `${BASE_URL}/items/authcode/test?steamId=${steamId}&authCode=${authCode}&shareCode=${shareCode}&token=${get(
          "token",
        )}`,
      );
      return true;
    } catch (error: any) {
      return false;
    }
  }

  public async PostMatchToken(
    steamId: string,
    authCode: string,
    shareCode: string,
  ): Promise<boolean> {
    try {
      await axios.put(
        `${BASE_URL}/items/authcode?steamId=${steamId}&token=${get("token")}`,
        { authCode, shareCode, steamId },
      );
      return true;
    } catch (error: any) {
      return false;
    }
  }

  public async ConnectGamersClub(
    steamId64: string,
    enable: boolean,
  ): Promise<boolean | string> {
    try {
      const { data } = await axios.get("https://jsonip.com/");
      await axios.patch(
        `${BASE_URL}/connect/gamersclub?steamId64=${steamId64}&enable=${enable}&ip=${
          data.ip
        }&token=${get("token")}`,
      );
      return true;
    } catch (error: any) {
      const notErrors = [404];
      const status = error?.response?.status;
      if (notErrors.includes(status)) {
        return error.response.data.msg;
      }
      return false;
    }
  }

  public async RefreshToken(refreshToken: string): Promise<string> {
    try {
      const { data } = await axios.post(
        `${BASE_URL}/users/auth/refresh?token=${refreshToken}`,
      );
      const token = data.token;
      return token;
    } catch (error: any) {
      localStorage.clear();
      window.location.reload();
      return "";
    }
  }

  private decodeTokenComponent(
    value:
      | WithImplicitCoercion<string>
      | { [Symbol.toPrimitive](hint: "string"): string },
  ) {
    // @ts-ignore
    const buff = new Buffer.from(value, "base64");
    const text = buff.toString("ascii");
    return JSON.parse(text);
  }

  public async DecodeToken(): Promise<TokenInterface | null> {
    try {
      const [headerEncoded, payloadEncoded] = get("token").split(".");

      // eslint-disable-next-line
      const [header, payload] = [headerEncoded, payloadEncoded].map(
        this.decodeTokenComponent,
      );

      this.TokenExpires = payload.exp;
      this.UserObjectId = payload._id;
      return payload;
    } catch (e) {
      return null;
    }
  }

  public async ClearAllLocalData() {
    localStorage.clear();
    window.location.reload();
  }
}

const get = (key: string) => {
  const data = localStorage.getItem(key);
  if (!data) return null;
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

export const ClearAllLocalData = async () => {
  localStorage.clear();
  window.location.reload();
};

export const SignOutButton = async () => {
  localStorage.clear();
  window.location.reload();
};

interface ILoginRequest {
  status: string;
  token: string;
}
export interface TokenInterface {
  escalated?: boolean;
  sub: string;
  _id: string;
  userGroup: string;
  userGroupActive: string;
  admin: boolean;
  refreshToken: string;
  discord: string;
  enabled: boolean;
  faceit: boolean;
  exp: number;
  SteamID: string;
}
export interface ProfileInterface {
  user: {
    _id: string;
    admin: boolean;
    avatar: string;
    userGroup: {
      active: boolean;
      type: string;
      updated: string;
      createdDate: string;
      expires: string;
      paymentDate: string;
    };
    discord: {
      username: string;
      id: string;
      avatar: string;
    };
    riot: {
      puuid: string;
      active: boolean;
      riodId: string;
    };
    faceit: {
      nickname: string;
      player_id: string;
      email: string;
      usedForSignUp: boolean;
    };
    epic: {
      usedForSignUp: boolean;
      displayName: string;
      id: string;
    };
    tiktok: {
      avatar: string | null;
      display_name: string | null;
      usedForSignUp: boolean;
    };
    google: { active: boolean };

    steam: {
      steamid: string;
      personaname: string;
      usedForSignUp: boolean;
    };
    scope: string[];
    rank: string;
    credits: {
      providerName: string;
      updated: string;
      createdDate: string;
    };
    discordSignUp: boolean;
    emailVerified: boolean;
    createdDate: string;
    username: string | null;
    ftue: {
      __typename: "Ftue";
      hasCompleted: boolean;
      isCS: boolean;
      step: string;
      stepHistory: string[];
      page: string | null;
      skip: {
        __typename: "FtueSkip";
        default: string | null;
        profile: string | null;
        games: string | null;
        platforms: string | null;
        steam: string | null;
        sharecode: string | null;
        socials: string | null;
        download: string | null;
        claim: string | null;
      };
    };
  };
  stats: {
    totalClips: string;
    totalViews: string;
  };
  profile: {
    id: string;
    profile: {
      countryCode: string;
      locale: string;
      socials: {
        twitch: string;
        tiktok: string;
        steam: string;
        instagram: string;
        twitter: string;
        youtube: string;
      };
      youtubeChannels: (string | null)[];
    };
    username: string;
    createdDate: string;
    appVersion: string;
    gamersClub: {
      enabled: boolean;
      steamId64: string;
    }[];
    settings: {
      usernameChange: number;
    };
    following: string[];
  };
  metadata: {
    hasSharecodes: boolean;
    hasClips: boolean;
    noEmailSet: boolean;
  };
}

export interface IMe {
  steam: {
    active: boolean;
    usedForSignUp: boolean;
    personaname: string;
    steamid: string;
  };
  faceit: {
    auth: {
      expires_in: string;
      access_token: string;
      refresh_token: string;
    };
    email: string;
    active: boolean;
    webhook: boolean;
    nickname: string;
    player_id: string;
  };
  tiktok: {
    active: boolean;
  };

  discord: {
    auth: {
      access_token: string;
      expires_in: string;
      refresh_token: string;
      scope: string;
      token_type: string;
    };
    id: string;
    username: string;
    avatar: string;
    discriminator: string;
    locale: string;
    mfa_enabled: boolean;
    verified: boolean;
  };
  _id: string;
}
export interface IAuthenticatedUserQuery {
  me: ProfileInterface;
}
