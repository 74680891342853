import {
  useEffect,
  useRef,
  MutableRefObject,
  useCallback,
  useState,
} from "react";
import { Box } from "rebass/styled-components";
import videojs from "video.js";
import "video.js/dist/video-js.css";
import { StyledPlayer } from "./styledComponents";

export type TSourceType = { src: string; type: string };
interface IVideoPlayerProps {
  mp4Source?: string;
  hlsSource?: string;
  analyticsProperties?: Record<string, unknown>;
  options?: videojs.PlayerOptions;
  refForVideoPlayer: MutableRefObject<videojs.Player | null>;
  onPlayStart?: () => void;
}

export const VideoPlayer = ({
  mp4Source,
  hlsSource,
  options,
  refForVideoPlayer,
  onPlayStart,
  analyticsProperties,
  ...props
}: IVideoPlayerProps) => {
  const videoRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<videojs.Player | null>(null);
  const [secondsWatched, setSecondsWatched] = useState(0);
  const [trackedView, setTrackedView] = useState(false);
  const interval = useRef<NodeJS.Timeout | undefined>();

  const startTrackingSecondsWatched = useCallback(() => {
    if (!interval.current) {
      interval.current = setInterval(() => {
        setSecondsWatched((prev) => prev + 1);
      }, 1000);
    }
  }, [interval]);

  const stopTrackingSecondsWatched = useCallback(() => {
    clearInterval(interval.current);
    interval.current = undefined;
  }, [interval]);

  useEffect(() => {
    if (!playerRef.current && videoRef.current) {
      const sources: TSourceType[] = [];
      const videoElement = document.createElement("video-js");

      if (mp4Source)
        sources.push({
          src: mp4Source,
          type: "video/mp4",
        });
      if (hlsSource)
        sources.push({
          src: hlsSource,
          type: "application/x-mpegURL",
        });

      videoElement.classList.add("vjs-big-play-centered");
      videoRef.current.appendChild(videoElement);

      playerRef.current = videojs(videoElement, {
        controls: true,
        fluid: true,
        sources,
        ...options,
      });
    }

    if (refForVideoPlayer) refForVideoPlayer.current = playerRef.current;
  }, [mp4Source, hlsSource, options, refForVideoPlayer]);

  useEffect(() => {
    if (playerRef.current) {
      if (hlsSource) {
        return playerRef.current.src(hlsSource);
      }
      if (mp4Source) {
        return playerRef.current.src(mp4Source);
      }
    }
  }, [mp4Source, hlsSource]);

  useEffect(() => {
    const player = playerRef.current;

    player?.on("play", () => {
      const playerVolume = localStorage.getItem("playerVolume");
      startTrackingSecondsWatched();
      if (!player.currentTime() && onPlayStart) onPlayStart();
      if (playerVolume) player.volume(parseFloat(playerVolume));
    });
    player?.on("pause", () => {
      stopTrackingSecondsWatched();
    });
    player?.on("volumechange", () => {
      localStorage.setItem("playerVolume", `${player.volume()}`);
    });

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [
    playerRef,
    onPlayStart,
    startTrackingSecondsWatched,
    stopTrackingSecondsWatched,
  ]);

  useEffect(() => {
    if (secondsWatched >= 5 && !trackedView) {
      window.rudderanalytics.track("Video Player - Watched 5 seconds", {
        ...analyticsProperties,
      });
      setTrackedView(true);
    }
  }, [secondsWatched, trackedView, analyticsProperties]);

  return (
    <StyledPlayer>
      <Box data-vjs-player {...props}>
        <Box ref={videoRef} />
      </Box>
    </StyledPlayer>
  );
};
