import React, { useRef } from "react";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

import { Modal } from "../../Wrappers";
import { AllstarModalState } from "../../../State/modals";
import { ModalType } from "../../../@types";
import { VideoPlayer } from "../../../VideoPlayer";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;
export const SimpleClipViewModal = () => {
  const [allstarModalState, setAllstarModalState] =
    useRecoilState(AllstarModalState);

  const videoPlayerRef = useRef(null);
  let mp4Source;
  if (allstarModalState?.data?.cf?.downloadUrl)
    mp4Source = allstarModalState?.data?.cf?.downloadUrl;
  else if (allstarModalState?.data?.clipLink)
    mp4Source = `${REACT_APP_S3_CLIP_BUCKET}/${allstarModalState?.data?.clipLink}`;
  if (allstarModalState.isOpen !== ModalType.SimpleClipViewer) return <></>;

  return (
    <>
      <Modal
        isModalOpen={allstarModalState.isOpen === ModalType.SimpleClipViewer}
        setModalOpen={(isOpen: boolean) =>
          setAllstarModalState({
            ...allstarModalState,
            isOpen: isOpen ? ModalType.SimpleClipViewer : ModalType.None,
          })
        }
        handleModalClose={undefined}
        disableOutsideClose={undefined}
      >
        <Flex flexDirection="column" alignItems="center">
          <StyledVideoContainer>
            <VideoPlayer
              hlsSource={allstarModalState?.data?.cf?.hls}
              mp4Source={mp4Source}
              refForVideoPlayer={videoPlayerRef}
              analyticsProperties={{
                contentType: "clip",
                identifier: allstarModalState?.data?._id,
                source: allstarModalState?.data?.viewSource,
              }}
              options={{
                controls: true,
                poster: `${REACT_APP_S3_CLIP_BUCKET}/${allstarModalState?.data?.clipImageThumb}`,
              }}
            />
          </StyledVideoContainer>
        </Flex>
      </Modal>
    </>
  );
};

const StyledVideoContainer = styled(Flex)`
  border-radius: 14px;
  flex-direction: column;
  overflow: hidden;
  width: 60vw;

  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    width: 98vw;
  }
`;
