import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { RiTimerLine } from "react-icons/ri";
import { BiPlus } from "react-icons/bi";

import {
  CS2MatchHistoryViewModel,
  SteamFriendsViewModel,
} from "../../../Views/MatchHistoryCS2/ViewModel";
import { Body, Headline, Subheader, Title3 } from "../../../Components/Text";
import { Button } from "../../../Components/Button";
import { Tag } from "../../../Components/Tag";
import { useUser } from "../../../Hooks/useUser";
import { ESupportedGames } from "../../../@types";
import { ProcessingNotification } from "../../../Components/ProcessingPill";
import {
  MapName,
  MatchHistoryMapIcon,
  MatchHistoryPlatformIcon,
} from "../../../Views/MatchHistoryCS2/styledComponents";
import { maps, platformIcons } from "../../../Views/MatchHistoryCS2/utils";
import { Divider } from "../../../Views/MatchHistory/styledComponents";
import { MatchHistorySteamFriendsRow } from "../../../Views/MatchHistoryCS2/SteamFriendRow";
import { formatDate } from "../utils";
import theme from "../../../../theme/themeThree";
import { LoadingSkeleton } from "../../../Components/Skeloton";
import { URLS } from "../../../Utilities/Maps";
import { TMatchHistory } from "../../../Views/MatchHistoryCS2/@types";
import { CS2MomentViewModel } from "../../MatchHistoryCS2/MatchContent/Moment/ViewModel";

export const OldMatchHistoryWidget = ({
  header,
  subheader,
  showDivider,
  onFinishedLoading,
  onHideWidget,
}: {
  header?: string;
  subheader?: string;
  showDivider?: boolean;
  onFinishedLoading?: () => void;
  onHideWidget?: () => void;
}) => {
  const { matchesLoading, matchesData, noSteamError } =
    CS2MatchHistoryViewModel({
      useWidgetQuery: true,
    });
  const { friendsData, friendsLoading } = SteamFriendsViewModel();

  const { requestClip } = CS2MomentViewModel();

  const { allstarUser } = useUser();
  const [clipsRequested, setClipsRequested] = useState([] as string[]);
  const [clipsProcessing, setClipsProcessing] = useState([] as string[]);

  const history = useHistory();

  useEffect(() => {
    if (onFinishedLoading) onFinishedLoading();
  }, [onFinishedLoading]);

  if (matchesLoading)
    return (
      <Flex flexDirection={"column"} style={{ gap: 20, padding: "15px" }}>
        {[1, 1, 1].map((_) => {
          return <LoadingSkeleton width={"100%"} height={100} />;
        })}
      </Flex>
    );

  if (noSteamError || !matchesData) {
    if (onHideWidget) onHideWidget();
    return <></>;
  }

  const { moments: matches } = matchesData.matchHistory;
  const topThreeMatches = matches.slice(0, 3) as TMatchHistory[];

  if (!topThreeMatches.length) {
    if (onHideWidget) onHideWidget();
    return <></>;
  }
  return (
    <>
      <WidgetContainer>
        {header && (
          <Box mt={4} ml={4} variant={"text.headingMedium"}>
            {header}
          </Box>
        )}
        {subheader && (
          <Box mt={3} ml={4} variant={"text.paragraphMedium"} color="chalk">
            {subheader}
          </Box>
        )}

        {topThreeMatches.map((match, index) => {
          const key = `id_${match.date}_${match.csgoMatchId}`;
          const momentsFiltered = match.matchMoments.filter(
            (moment) => !moment.hasClip,
          );
          const potgRound = match.potg?.round;
          const potgOfFiltered = momentsFiltered.find(
            (moment) => moment.round === potgRound,
          );
          const roundToDisplay = potgOfFiltered || momentsFiltered[0];

          if (!roundToDisplay) return;

          const isProcessing = clipsProcessing.includes(key);
          const steamFriends = friendsData?.steamFriends;
          return (
            <>
              <Divider />
              <MomentCard
                style={{
                  marginBottom: index === 2 ? 20 : 0,
                  paddingLeft: 15,
                  paddingRight: 15,
                }}
              >
                <StyledFlex id={key}>
                  <MatchHistoryMapIcon img={maps[match.map] || maps["Default"]}>
                    {match.platform && (
                      <MatchHistoryPlatformIcon
                        img={platformIcons(match.platform)}
                      />
                    )}
                  </MatchHistoryMapIcon>{" "}
                  <HideOnMobileColumn
                    style={{
                      height: "100%",
                      marginTop: "5px",
                      alignSelf: "center",
                    }}
                  >
                    <MapName style={{ marginTop: 0, marginBottom: 5 }}>
                      {match.map}
                    </MapName>
                    <span
                      style={{
                        color: theme.colors.chalk,
                        fontSize: theme.text.footnote.fontSize,
                      }}
                    >
                      {formatDate(match.date)}
                    </span>{" "}
                    {!friendsLoading && friendsData?.steamFriends && (
                      <Box mt={2}>
                        <MatchHistorySteamFriendsRow
                          match={match}
                          size="extraTiny"
                          steamFriends={steamFriends}
                        ></MatchHistorySteamFriendsRow>
                      </Box>
                    )}
                  </HideOnMobileColumn>
                  <Column
                    style={{
                      height: "100%",
                      marginTop: "5px",
                      alignSelf: "center",
                    }}
                  >
                    <StyledHeadline>
                      {`${roundToDisplay.clipTitle} on ${match.map}`}
                    </StyledHeadline>
                    <StatsOverviewText>
                      <RiTimerLine
                        size={16}
                        color={"#4DBD94"}
                        style={{
                          marginBottom: -2,
                          marginRight: 4,
                        }}
                      />
                      Round {roundToDisplay?.round} : {roundToDisplay?.side}{" "}
                      Side
                    </StatsOverviewText>
                    <Row
                      style={{
                        justifyContent: "start",
                        marginTop: "5px",
                        flexWrap: "wrap",
                      }}
                    >
                      {roundToDisplay?.events.map((x: string) => (
                        <StyledTag
                          key={`tag-${x}`}
                          cursor="pointer"
                          title={x}
                          onClick={undefined}
                        />
                      ))}
                    </Row>
                  </Column>
                </StyledFlex>
                {!isProcessing && (
                  <Button
                    style={{ borderRadius: "24px" }}
                    size="tiny"
                    disabled={clipsRequested.includes(key)}
                    flex={true}
                    pl={4}
                    pr={5}
                    onClick={async () => {
                      setClipsRequested((prev) => [...prev, key]);
                      await requestClip({
                        matchId: match.csgoMatchId,
                        round: roundToDisplay!.round,
                      });
                      setClipsRequested((prev) =>
                        prev.filter((x) => x !== key),
                      );
                      setClipsProcessing((prev) => [...prev, key]);
                    }}
                    onClickCapture={() => {
                      window.rudderanalytics.track(
                        "Match History - Request Clip",
                        {
                          userGroup: allstarUser?.user?.userGroup?.type,
                          matchId: match.csgoMatchId,
                          round: roundToDisplay!.round,
                          game: ESupportedGames.CS2,
                          isWidget: true,
                        },
                      );
                    }}
                  >
                    <BiPlus
                      size={18}
                      style={{
                        marginBottom: -4,
                        marginRight: 4,
                        marginLeft: 0,
                      }}
                    />
                    Create Clip
                  </Button>
                )}
                {isProcessing && (
                  <ProcessingNotification
                    clickable={true}
                    user={allstarUser.user._id}
                  />
                )}
              </MomentCard>{" "}
            </>
          );
        })}
        <Divider />
        <Flex alignItems={"center"} justifyContent="center" mb={8} mt={6}>
          <Flex
            flexDirection={"column"}
            justifyContent="center"
            alignItems="center"
          >
            <StyledTitle3 my={3}>Want to create more?</StyledTitle3>
            <StyledBody color="chalk">
              Head over to Match History to access all of your recent matches
              and get going!
            </StyledBody>
            <StyledButton
              size="tiny"
              flex={true}
              onClick={() => {
                window.rudderanalytics.track(
                  "Match History - Go To Match History",
                  {
                    isWidget: true,
                  },
                );
                history.push(URLS.MATCH_HISTORY);
              }}
            >
              Go to Match History
            </StyledButton>
          </Flex>
        </Flex>
      </WidgetContainer>
      {showDivider && <Divider />}
    </>
  );
};
const StyledTag = styled(Tag)`
  margin-bottom: 0px;
`;

const StyledHeadline = styled(Headline)`
  font-weight: 600;
  margin-bottom: 5px;
`;

const StyledHeadlineLink = styled(Headline)`
  font-weight: 600;
  cursor: pointer;
`;

const StatsOverviewText = styled(Subheader)`
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: ${(props) => props.theme.colors.chalk};
`;

const StyledFlex = styled(Flex)`
  gap: 30px;
  flex-direction: row;
  width: 430px;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 240px;
    gap: 15px;
  }
`;

const Column = styled(Flex)`
  flex-direction: column;
`;
const HideOnMobileColumn = styled(Column)`
  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    display: none;
  }
`;

const Row = styled(Flex)`
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  gap: 0px;
`;

const MomentCard = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  gap: 10px;
`;

const WidgetContainer = styled(Flex)`
  flex-direction: column;
  border: 2px solid ${({ theme }) => theme.colors.chalkAlpha20};
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: ${({ theme }) => theme.colors.darkerNed};
  padding: 15px;
`;

const StyledBody = styled(Body)`
  max-width: 540px;
  text-align: center;
`;

const StyledTitle3 = styled(Title3)`
  font-weight: 600;
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
  border-radius: 24px;
  padding: 0px 20px;
  height: 40px;
`;
