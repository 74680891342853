import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import styled from "styled-components";
import { Box, Flex, Text } from "rebass/styled-components";

import { CS2MatchHistoryViewModel } from "../../../MatchHistoryCS2/ViewModel";
import { Body, Title3 } from "../../../../Components/Text";
import { Button } from "../../../../Components/Button";
import { maps } from "../../../MatchHistoryCS2/utils";
import {
  Divider,
  MatchRowImage,
  MatchRowImageContainer,
} from "../../styledComponents";
import { BiRightArrowAlt } from "react-icons/bi";
import { formatDate } from "../../utils";
import { LoadingSkeleton } from "../../../../Components/Skeloton";
import { URLS } from "../../../../Utilities/Maps";
import { TMatchHistory } from "../../../MatchHistoryCS2/@types";

import { WidgetGoToMatchId } from "../../../../State/matchHistory";
import { MomentsList } from "../../../MatchHistoryCS2/MatchContent/MomentsList";

export const CSMoments = ({
  header,
  subheader,
  onFinishedLoading,
  onHideWidget,
}: {
  header?: string;
  subheader?: string;
  onFinishedLoading?: () => void;
  onHideWidget?: () => void;
}) => {
  const { matchesLoading, matchesData, noSteamError } =
    CS2MatchHistoryViewModel({ useWidgetQuery: true });
  const setGoToMatchId = useSetRecoilState(WidgetGoToMatchId);
  const history = useHistory();

  useEffect(() => {
    if (matchesLoading) return;
    if (onFinishedLoading) onFinishedLoading();
  }, [matchesLoading, onHideWidget, onFinishedLoading]);

  if (matchesLoading)
    return (
      <Flex flexDirection={"column"} style={{ gap: 20, padding: "15px" }}>
        {[1, 1, 1].map((_) => {
          return <LoadingSkeleton width={"100%"} height={100} />;
        })}
      </Flex>
    );
  if (noSteamError || !matchesData) return <></>;

  const { moments: matches = [] }: { moments: TMatchHistory[] } =
    matchesData?.matchHistory || { moments: [] };

  if (!matches.length) return <></>;
  const player = matchesData?.matchHistory?.player;
  return (
    <>
      <WidgetContainer>
        {header && (
          <Box mt={4} ml={4} variant={"text.headingMedium"}>
            {header}
          </Box>
        )}
        {subheader && (
          <Box mt={3} ml={4} variant={"text.paragraphMedium"} color="chalk">
            {subheader}
          </Box>
        )}

        {matches.map((match, index) => {
          const key = `id_${match.date}_${match.csgoMatchId}`;
          return (
            <>
              <MatchRow key={key}>
                <MapRow>
                  <MatchRowImageContainer mr={4}>
                    <MatchRowImage
                      src={maps[match.map] || maps["default"]}
                      maxHeight={"48px"}
                    />
                  </MatchRowImageContainer>
                  <Column>
                    <Text variant={"text.labelMedium"}>{match.map}</Text>
                    <Text color="chalk" variant={"text.labelSmall"}>
                      {formatDate(match.date)}
                    </Text>
                  </Column>
                </MapRow>
                <Column>
                  <Text variant={"text.labelXSmall"} color={"chalk"}>
                    Score
                  </Text>
                  <Text
                    variant={"text.labelMedium"}
                    color={match.result.win ? "envy" : "alert"}
                  >
                    {match.result.score}
                  </Text>
                </Column>
                <Column>
                  <Text variant={"text.labelXSmall"} color={"chalk"}>
                    Kills
                  </Text>
                  <Text variant={"text.labelMedium"} color={"ice"}>
                    {match.kills}
                  </Text>
                </Column>
                <Column>
                  <Text variant={"text.labelXSmall"} color={"chalk"}>
                    Deaths
                  </Text>
                  <Text variant={"text.labelMedium"} color={"ice"}>
                    {match.deaths}
                  </Text>
                </Column>
                <Column>
                  <GoToMatchContainer
                    textAlign={"center"}
                    justifyContent={"center"}
                    alignContent={"center"}
                  >
                    <Text
                      variant={"text.labelMedium"}
                      color={"envy"}
                      textAlign={"center"}
                      justifyContent={"center"}
                      alignContent={"center"}
                      onClick={() => {
                        window.rudderanalytics.track(
                          "Match History - Go to Match",
                          {
                            isWidget: true,
                          },
                        );
                        setGoToMatchId(match.csgoMatchId);
                        history.push(URLS.MATCH_HISTORY);
                      }}
                    >
                      Go To Match
                    </Text>
                    <RightArrow />
                  </GoToMatchContainer>
                </Column>
              </MatchRow>
              <Flex flexDirection="column" flexGrow="1" mt={"24px"}>
                <MomentsList match={match} isWidget={true} player={player} />
              </Flex>
            </>
          );
        })}
      </WidgetContainer>
    </>
  );
};

const GoToMatchContainer = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`;
const RightArrow = styled(BiRightArrowAlt)`
  color: ${({ theme }) => theme.colors.envy};
  width: 20px;
  height: 20px;
`;
const MatchRow = styled(Flex)`
  justify-content: space-between;
  border-top: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  padding-top: 16px;
`;
const Column = styled(Flex)`
  flex-direction: column;
  align-items: start;
  justify-content: center;
  gap: 6px;
  min-width: 50px;
`;

const MapRow = styled(Flex)`
  gap: 5px;
`;
const WidgetContainer = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

const StyledBody = styled(Body)`
  max-width: 540px;
  text-align: center;
`;

const StyledTitle3 = styled(Title3)`
  font-weight: 600;
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
  border-radius: 24px;
  padding: 0px 20px;
  height: 40px;
`;
