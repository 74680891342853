import { Box, Flex, Image, Text } from "rebass/styled-components";
import styled from "styled-components";
import { Button } from "../../../Components/Button";
import AllstarIcon from "../../../../assets/images/brand/Wordmark-Light-Tag.png";
export const CreateForFriend = () => {
  return (
    <StyledBox>
      <Flex
        flexDirection="column"
        padding={7}
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{ gap: 20 }}
        maxWidth={400}
      >
        <StyledAllstarIcon src={AllstarIcon} mb={5} />
        <Flex
          flexDirection={"column"}
          justifyContent={"center"}
          alignItems={"center"}
          mb={3}
        >
          <Text variant="text.headingSmall" textAlign={"center"} mb={3}>
            This feature is coming soon!
          </Text>
          <Text
            variant="text.paragraphMedium"
            color="chalk"
            maxWidth={450}
            textAlign={"center"}
          >
            For now, you can send your friend a link to this page so that they
            can get started on creating their own moments.
          </Text>
        </Flex>
        <Box>
          <Button
            mt={4}
            size="small"
            flex={true}
            onClick={() => {
              window.rudderanalytics.track("Match History - Share Moment");
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            Copy Link & Share
          </Button>
        </Box>
      </Flex>
    </StyledBox>
  );
};

const StyledBox = styled(Box)`
  border-radius: 16px;
  mix-blend-mode: normal;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  background-color: #10161a;
  max-width: 400px;
`;

const StyledAllstarIcon = styled(Image)`
  width: 110px;
`;

const Row = styled(Flex)`
  align-items: start;
  justify-content: start;
  gap: 15px;
`;
