import { useState, lazy, Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import FragmentSupportingSwitch from "./utils";
import styled, { createGlobalStyle } from "styled-components";
import { useGetUser, UserStateProvider } from "./userStore";
import Background from "./components/background";
import { Nav } from "./components/organisms/nav";
import { NavSignup } from "./components/organisms/navSignup";
import { Error } from "./components/atoms/form/error";
import Footer from "./components/organisms/footer";
import { ResetPassword } from "./MVVM/Views/ResetPassword";

// temp page for dreamhack 2024
import Dreamhack2024 from "./MVVM/Views/Dreamhack2024";

/* Public Routes */
import Home from "./pages/home/index";
import StudioMarketingPage from "./pages/studioMarketing/index";
import ResetRequest from "./pages/resetRequest/index";
import Activation from "./pages/activation/index";
import Edit from "./MVVM/Components/EditClipOrMontage";
import Terms from "./pages/legal/terms";
import Privacy from "./pages/legal/privacy";
import VulnerabilityDisclosurePolicy from "./pages/legal/vulnerability-disclosure-policy";
import Information from "./pages/legal/information";
import ComingSoon from "./pages/studio/soon";
import CreateOrLogin from "./pages/interstitial/createOrLogin";
import CreateOrLoginTikTok from "./pages/interstitial/createOrLoginTikTok";
import Faq from "./pages/faq/index";
import Culture from "./pages/culture/index";
import Verify from "./pages/verify/index";
import TikTokEmail from "./MVVM/Views/Authentication/Components/TikTokEmail";
import About from "./pages/about/index";
import Brand from "./pages/brand/index";
import { Flex, Box } from "rebass/styled-components";
import LoadingSVG from "./loading.svg";
import AppOneLink from "./pages/apponelink";
import AppStoreChooser from "./pages/appstorechooser";

/** MVVM Views */
import { EAuthenticationTypes } from "./MVVM/Views/Authentication/@types";

/** MVVM  Other*/
import { EClipsViews } from "./MVVM/@types";
import { useIsDesktopApp } from "./MVVM/Hooks/useIsDesktopApp";
import { URLS } from "./MVVM/Utilities/Maps";
import { GetApp } from "./MVVM/Components/GetApp";
import { useIsMobileApp } from "./MVVM/Hooks/useIsMobileApp";
import { PrefillLedgerBalance } from "./MVVM/Providers";
import { ModalManager } from "./MVVM/Components/ModalManager";
import { SiteNotice } from "./MVVM/Components/SiteNotice";

const LazyHomepage = lazy(() =>
  import("./MVVM/Views/Homepage" /* webpackChunkName: 'homepage' */),
);

const LazyProfile = lazy(() =>
  import("./MVVM/Views/Profile" /* webpackChunkName: 'profile' */),
);

const LazyAccount = lazy(() =>
  import("./pages/profileSettings" /* webpackChunkName: 'account' */),
);

const LazyAuthenticate = lazy(() =>
  import("./MVVM/Views/Authenticate" /* webpackChunkName: 'authenticate' */),
);

const LazyClipsPage = lazy(() =>
  import("./MVVM/Views/Clips" /* webpackChunkName: 'clips' */),
);

const LazyClipPage = lazy(() =>
  import("./MVVM/Views/Clip" /* webpackChunkName: 'clip' */),
);

const LazyClipFullPage = lazy(() =>
  import("./MVVM/Views/ClipFull" /* webpackChunkName: 'clipFull' */),
);

const LazyError404 = lazy(() =>
  import("./MVVM/Views/404" /* webpackChunkName: 'error404' */),
);

const LazyStudioPage = lazy(() =>
  import("./MVVM/Views/Studio" /* webpackChunkName: 'studioPage' */),
);

const LazyFeatureMontage = lazy(() =>
  import(
    "./MVVM/Views/FeatureMontage" /* webpackChunkName: 'featureMontage' */
  ),
);

const LazyPricing = lazy(() =>
  import("./MVVM/Views/Pricing" /* webpackChunkName: 'pricing' */),
);

const LazyCreateMontagePage = lazy(() =>
  import(
    "./MVVM/Views/CreateMontage" /* webpackChunkName: 'createMontagePage' */
  ),
);

const LazyOauthPage = lazy(() =>
  import("./MVVM/Views/Oauth" /* webpackChunkName: 'oauthPage' */),
);

const LazyOverwolf = lazy(() =>
  import("./MVVM/Views/Overwolf" /* webpackChunkName: 'overwolf' */),
);

const LazyAllstarIO = lazy(() => {
  import("./MVVM/Views/IO" /* webpackChunkName: 'allstarIO' */);
});

const LazyAuthentication = lazy(() =>
  import(
    "./MVVM/Views/Authentication" /* webpackChunkName: 'authentication' */
  ),
);

const LazySetup = lazy(() =>
  import("./MVVM/Views/Setup" /* webpackChunkName: 'setup' */),
);

const LazyPartners = lazy(() => import("./MVVM/Views/Partners"));

const LazyMatchHistory = lazy(() =>
  import("./MVVM/Views/MatchHistory" /* webpackChunkName: 'matchHistory' */),
);

const LazyIframeLeaderboard = lazy(() =>
  import(
    "./MVVM/Views/Competitions/Views/iframe/Leaderboard" /* webpackChunkName: 'leaderboardIframe' */
  ),
);
const LazyCompetitions = lazy(() =>
  import("./MVVM/Views/Competitions" /* webpackChunkName: 'competitions' */),
);
const LazyCampaignCompetitions = lazy(() =>
  import(
    "./MVVM/Views/Competitions/Views/[slug]" /* webpackChunkName: 'campaignCompetitions' */
  ),
);
const LazyCampaignPlayCompetitions = lazy(() =>
  import(
    "./MVVM/Views/Competitions/Views/Play" /* webpackChunkName: 'matchHistory' */
  ),
);
const LazyCampaignWinnersCompetitions = lazy(() =>
  import(
    "./MVVM/Views/Competitions/Views/Winners" /* webpackChunkName: 'competitionWinners' */
  ),
);
const LazyAddShareCodes = lazy(() =>
  import("./MVVM/Views/AddShareCodes"),
); /* webpackChunkName: 'addShareCodes' */

const LazyPaymentValidation = lazy(() =>
  import("./MVVM/Views/PaymentValidation"),
); /* webpackChunkName: 'paymentValidation' */

const StyledImage = styled.img`
  max-width: 50px;
  margin: auto;
`;

const PrivateRoute = ({ bypassAuth, ...props }) => {
  const user = useGetUser();
  if (!user && !bypassAuth?.()) {
    return <Redirect to={`/login?next=${window.location.pathname}`} />;
  }
  return <Route {...props} />;
};

export default function () {
  /* IMPORTANT: NO RECOIL IN THIS FILE BAD BAD BAD BAD BAD BAD BAD BAD BAD BAD */

  const isDesktopView = useIsDesktopApp();
  const isMobileApp = useIsMobileApp();
  const [isModalOpen, setModalOpen] = useState(false);
  const searchString = window.location.search;
  const showModal = () => setModalOpen(!isModalOpen);
  const urlParams = new URLSearchParams(window.location.search);
  const game = urlParams.get("game");

  if (game) {
    localStorage.setItem("game", game);
  }
  if (window.document.referrer) {
    localStorage.setItem("initialReferrer", window.document.referrer);
  }

  return (
    <UserStateProvider>
      <GlobalStyle />
      <LayeredBackground />
      <GetApp />
      <SiteNotice />
      <PrefillLedgerBalance />
      <LayeredContent>
        <Route
          path="/"
          render={(props) => {
            const path = props.location.pathname;

            if (
              path.includes(URLS.REMIX) ||
              path.includes(URLS.STUDIO) ||
              path.includes(URLS.MONTAGE) ||
              path.includes(URLS.OVERWOLF) ||
              path.includes(URLS.ALLSTAR_GAMERS_CLUB) ||
              isDesktopView.isDesktop ||
              isMobileApp.isMobileApp ||
              path.includes("partner/claim") ||
              path.includes("partner/signup") ||
              path.includes("mobile-app-link") ||
              path.match("signup") ||
              path.match("register") ||
              path.match("login") ||
              path.match("reset") ||
              path.match("register") ||
              path.match("setup") ||
              path.match("oauth") ||
              path.includes("authenticate/mobile") ||
              path.includes("iframe")
            ) {
              return <></>;
            }
            if (MINIMAL_ROUTES.includes(props.location.pathname)) {
              return <NavSignup />;
            }
            return <Nav {...props} showModal={showModal} />;
          }}
        />

        <SiteContent id="sitecontent">
          <FragmentSupportingSwitch>
            <Route
              exact
              path="/mobile-app-link/terms-of-use"
              component={Terms}
            />
            <Route exact path="/mobile-app-link/faq" component={Faq} />
            <Route
              exact
              path="/mobile-app-link/privacy-policy"
              component={Privacy}
            />
            <Route
              exact
              path="/howitworks"
              render={(props) => <Home {...props} showModal={showModal} />}
            />
            <Route exact path="/apponelink" component={AppOneLink} />
            <Route exact path="/dreamhack2024" component={Dreamhack2024} />
            <Route exact path="/appstorechooser" component={AppStoreChooser} />
            <Route exact path="/" component={getChunkable(LazyHomepage)} />
            <Route
              exact
              path="/login"
              render={getChunkable(LazyAuthentication, {
                authType: EAuthenticationTypes.LOGIN,
              })}
            />
            <Route exact path="/signup">
              <Redirect to="/register" />
            </Route>
            <Route
              path="/authenticate/:route"
              render={getChunkable(LazyAuthenticate)}
            />
            <Route path="/oauth/:route" render={getChunkable(LazyOauthPage)} />
            <Route exact path="/partner/signup/:partner">
              <Redirect to="/register" />
            </Route>
            <Route exact path="/subscribe">
              <Redirect to="/subscriptions" />
            </Route>
            <Route exact path="/updates">
              <Redirect to="/upgrade" />
            </Route>
            <Route exact path="/referred">
              <Redirect to="/signup" />
            </Route>
            <Route exact path="/reset" component={ResetRequest} />
            <Route
              exact
              path="/competitions"
              render={getChunkable(LazyCompetitions)}
            />
            <Route
              exact
              path="/competitions/:campaign"
              render={getChunkable(LazyCampaignCompetitions)}
            />
            <Route
              exact
              path="/competitions/:campaign/play"
              render={getChunkable(LazyCampaignPlayCompetitions)}
            />
            <Route
              exact
              path="/competitions/:campaign/winners"
              render={getChunkable(LazyCampaignWinnersCompetitions)}
            />
            <Route
              exact
              path="/iframe/competitions/:campaign"
              render={getChunkable(LazyIframeLeaderboard)}
            />
            <Route
              exact
              path="/gavin"
              component={() => {
                window.location = "https://linkedin.com/in/gavinsilver";
              }}
            />
            <Route
              exact
              path="/download"
              component={() => {
                window.location =
                  "https://download.overwolf.com/install/Download?Name=Allstar&ExtensionId=ccbanlanlcmninogggoifelckghdgccjaanopmpn";
                return <Redirect to="/clips" />;
              }}
            />
            <Route
              exact
              path="/cs2betaapp"
              component={() => {
                window.location =
                  "https://media.allstar.gg/static/Allstar-Desktop-Application-Setup-3.0.14.exe";
                return <Redirect to="/clips" />;
              }}
            />
            <Route
              exact
              path="/discordinvite"
              component={() => {
                window.location = "https://discord.gg/playsharestar";
                return null;
              }}
            />
            <Route exact path="/u/:user" render={getChunkable(LazyProfile)} />
            <Route exact path="/profile" render={getChunkable(LazyProfile)} />
            {/* ROUTES THAT REQUIRE LOGIN */}
            <PrivateRoute
              exact
              path="/notifications"
              component={getChunkable(LazyAccount, {
                showModal,
                view: "notifications",
              })}
            />
            <PrivateRoute
              exact
              path="/dashboard"
              component={getChunkable(LazyAccount, {
                showModal,
                view: "dashboard",
              })}
            />
            <PrivateRoute
              exact
              path="/accountsettings"
              component={getChunkable(LazyAccount, {
                showModal,
                view: "accountSettings",
              })}
            />
            <PrivateRoute
              exact
              path="/connectedaccounts"
              component={getChunkable(LazyAccount, {
                showModal,
                view: "connectedAccounts",
              })}
            />
            <PrivateRoute
              exact
              path="/subscriptions"
              component={getChunkable(LazyAccount, {
                showModal,
                view: "subscriptions",
              })}
            />
            <PrivateRoute
              path={"/payment/validation"}
              exact
              component={getChunkable(LazyPaymentValidation)}
            />
            <PrivateRoute
              path={"/add-share-codes"}
              exact
              component={getChunkable(LazyAddShareCodes)}
            />
            <Route
              path={"/match-history"}
              exact
              component={getChunkable(LazyMatchHistory)}
            />
            <Route
              path={"/match-history/:page"}
              exact
              component={getChunkable(LazyMatchHistory)}
            />
            <Route
              path={"/remix/:shareid"}
              render={getChunkable(LazyStudioPage, { route: "remix" })}
            />
            <Route
              path={"/studio/:cardId"}
              render={getChunkable(LazyStudioPage, { route: "studio" })}
            />
            <Route
              path={"/studio"}
              component={getChunkable(LazyStudioPage, { route: "studio" })}
            />
            <Route path="/soon" component={ComingSoon}></Route>
            <Route path="/edit/:shareId" component={Edit}></Route>
            <Route
              exact
              path="/activation/download-app"
              component={Activation}
            />
            <Route exact path="/activation/verify" component={Activation} />
            <Route
              exact
              path="/activation/connect-discord"
              render={(props) => <Activation {...props} step={2} />}
            />
            <Route exact path="/legal/terms-of-use" component={Terms} />
            <Route exact path="/legal/privacy-policy" component={Privacy} />
            <Route
              exact
              path="/legal/vulnerability-disclosure-policy"
              component={VulnerabilityDisclosurePolicy}
            />
            <Route
              exact
              path="/legal/ad-terms-and-conditions"
              component={getChunkable(LazyAllstarIO, {})}
            />
            <Route
              exact
              path="/legal/do-not-sell-my-personal-information"
              component={Information}
            />
            <Route
              exact
              path="/clip/:id"
              component={getChunkable(LazyClipFullPage)}
            />
            <Route exact path="/clip" component={getChunkable(LazyClipPage)} />
            <Route
              exact
              path="/montage"
              render={getChunkable(LazyClipPage, {
                route: "montage",
                isMontage: true,
              })}
            />
            <Route path="/clips">
              <Redirect to="/" />
            </Route>
            <Route
              exact
              path="/montages"
              render={getChunkable(LazyClipsPage, {
                defaultView: EClipsViews.Montages,
              })}
            />
            <Route
              exact
              path="/montages/create"
              render={getChunkable(LazyCreateMontagePage)}
            />
            <Route exact path="/myallstar">
              <Redirect to={`/dashboard${searchString}`}></Redirect>
            </Route>
            <Route exact path="/culture">
              <Redirect to={"/careers"}></Redirect>
            </Route>
            <Route exact path="/verify" component={Verify} />
            <Route exact path="/about" component={About} />
            <Route exact path="/careers" component={Culture} />{" "}
            <Route
              exact
              path="/overwolf"
              component={getChunkable(LazyOverwolf)}
            />
            <Route exact path="/brand" component={Brand} />
            <Route exact path="/allstarbot">
              <Redirect to={"/dashboard"}></Redirect>
            </Route>
            <Route exact path="/interstitial" component={CreateOrLogin} />
            <Route
              exact
              path="/interstitialtiktok"
              component={CreateOrLoginTikTok}
            />
            <Route exact path="/404" component={getChunkable(LazyError404)} />
            <Route
              exact
              path="/allstar/gamersclub"
              render={getChunkable(LazyOverwolf, {
                cta: "signup",
              })}
            />
            <Route exact path="/finishsignup" component={TikTokEmail} />
            <Route
              exact
              path="/feature/allstarstudio"
              component={StudioMarketingPage}
            />
            <Route
              exact
              path="/feature/montages"
              component={getChunkable(LazyFeatureMontage)}
            />
            <Route exact path={"/pro"}>
              <Redirect to={URLS.UPGRADE}></Redirect>
            </Route>
            <Route exact path={"/partnerships"}>
              <Redirect to="/partners"></Redirect>
            </Route>
            <Route
              exact
              path={URLS.UPGRADE}
              component={getChunkable(LazyPricing)}
            />
            <Route
              exact
              path="/support"
              component={() => {
                window.open(
                  "https://help.allstar.gg/hc/en-us/requests/new",
                  "_blank",
                );
                return <Redirect to="/clips" />;
              }}
            />{" "}
            <Route
              exact
              path="/mobileplaystore"
              component={() => {
                window.open(
                  "https://play.google.com/apps/testing/com.androidapp.gg.allstar",
                  "_blank",
                );
                return <Redirect to="/clips" />;
              }}
            />
            <Route
              exact
              path="/reset/form"
              render={(props) => <ResetPassword {...props} />}
            />
            <Route
              exact
              path="/faq"
              component={() => {
                window.open("https://help.allstar.gg/", "_blank");
                return <Redirect to="/clips" />;
              }}
            />
            <Route
              exact
              path="/register"
              render={getChunkable(LazyAuthentication, {
                authType: EAuthenticationTypes.SIGNUP,
              })}
            />
            <Route path="/setup" render={getChunkable(LazySetup)} />
            <Route path="/partners" render={getChunkable(LazyPartners)} />
            <Route component={getChunkable(LazyError404)} />
          </FragmentSupportingSwitch>
        </SiteContent>

        <Route
          path="/"
          render={(props) => {
            if (
              MINIMAL_ROUTES.includes(props.location.pathname) ||
              isDesktopView.isDesktop ||
              isMobileApp.isMobileApp ||
              window.location.pathname.includes("partner/claim") ||
              window.location.pathname.includes("partner/signup") ||
              window.location.pathname.includes("setup") ||
              window.location.pathname.includes("signup") ||
              window.location.pathname.includes("register") ||
              window.location.pathname.includes("login") ||
              window.location.pathname.includes("studio") ||
              window.location.pathname.includes("remix") ||
              window.location.pathname.includes("oauth") ||
              window.location.pathname.includes("/clip") ||
              window.location.pathname.includes("/montage") ||
              window.location.pathname.includes("/match-history") ||
              window.location.pathname.includes("/add-share-codes") ||
              window.location.pathname.includes("authenticate/mobile") ||
              window.location.pathname.includes("iframe")
            ) {
              return <></>;
            } else {
              return <Footer {...props} showModal={showModal} />;
            }
          }}
        />
      </LayeredContent>
      <ModalManager />
    </UserStateProvider>
  );
}

function ErrorFallback({ resetErrorBoundary }) {
  return (
    <div style={{ margin: "auto" }}>
      <Error>Failed to load data. Please reload the page and try again</Error>
      <button
        onClick={() => {
          resetErrorBoundary();
        }}
      >
        Try again
      </button>
    </div>
  );
}
const FallbackSVG = () => <StyledImage src={LoadingSVG} alt="loading" />;
const getChunkable = (Component, extraProps) => (props) => (
  <ErrorBoundary FallbackComponent={ErrorFallback}>
    <Suspense fallback={<FallbackSVG />}>
      <Component {...props} {...extraProps} />
    </Suspense>
  </ErrorBoundary>
);

const GlobalStyle = createGlobalStyle`
  html {
    @media (max-width: ${(props) => props.theme.breaks.narrow}) {
      font-size: 14px;
    }
  }
`;
const SiteContent = styled(Flex)`
  flex-direction: column;
  min-height: calc(
    100vh - ${(props) => props.theme.sizes[1] + props.theme.sizes[2]}px
  );
`;
const LayeredBackground = styled(Background)`
  z-index: 1;
`;
const LayeredContent = styled(Box)`
  position: relative;
  z-index: 2;
`;

const MINIMAL_ROUTES = [
  "/referred",
  "/overwolf",
  "/allstar/gamersclub",
  "/mobile-app-link/terms-of-use",
  "/mobile-app-link/privacy-policy",
  "/mobile-app-link/login",
  "/mobile-app-link/signup",
  "/mobile-app-link/faq",
  "/login",
  "/signup",
  "/register",
  "/reset",

  URLS.MONTAGE,
];
