import styled from "styled-components";
import { Flex, Image, Text } from "rebass/styled-components";

import { ReactComponent as HistoryLogo } from "../../../Assets/Nav/nav-history.svg";
import { CSMoments } from "./CSMoments";
import { CS2MatchHistoryViewModel } from "../../MatchHistoryCS2/ViewModel";
import { LoadingSkeleton } from "../../../Components/Skeloton";
import { useEffect, useState } from "react";
import { useUser } from "../../../Hooks/useUser";
import { Divider } from "../../../Components/Divider";
import { useHistory } from "react-router-dom";
import { URLS } from "../../../Utilities/Maps";
import { Body, Title3 } from "../../../Components/Text";
import { Button } from "../../../Components/Button";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;
export const MatchHistoryWidget = ({
  showDivider,
  onFinishedLoading,
  onHideWidget,
}: {
  showDivider?: boolean;
  onFinishedLoading?: () => void;
  onHideWidget?: () => void;
}) => {
  const { matchesLoading, matchesData, noSteamError } =
    CS2MatchHistoryViewModel({
      useWidgetQuery: true,
    });

  const history = useHistory();

  useEffect(() => {
    if (onFinishedLoading) onFinishedLoading();
  }, [onFinishedLoading]);

  if (matchesLoading)
    return (
      <Flex flexDirection={"column"} style={{ gap: 20, padding: "15px" }}>
        {[1, 1, 1].map((_) => {
          return <LoadingSkeleton width={"100%"} height={100} />;
        })}
      </Flex>
    );

  if (noSteamError || !matchesData) {
    if (onHideWidget) onHideWidget();
    return <></>;
  }
  const { moments: matches } = matchesData.matchHistory;
  if (!matches.length) {
    if (onHideWidget) onHideWidget();
    return <></>;
  }
  return (
    <>
      <WidgetContainer>
        <WidgetHeader>
          <WidgetTitleContainer>
            <Logo />
            <Text variant={"text.headingMedium"} color="ice">
              Your Recent Top Plays
            </Text>
          </WidgetTitleContainer>
          <Text variant="text.labelMedium" color="chalk">
            Here are some of your best moments from recent matches that you
            haven't clipped yet.
          </Text>
        </WidgetHeader>

        <CSMoments />
        <Divider />
        <GoToMatchHistoryContainer>
          <WantToCreateMoreContainer
            flexDirection={"column"}
            justifyContent="center"
            alignItems="start"
            maxWidth={"300px"}
          >
            <StyledTitle3 my={3}>Want to create more?</StyledTitle3>
            <StyledBody color="chalk" variant="text.labelMedium">
              Head over to Match History to access all of your recent matches
              and get going!
            </StyledBody>
            <StyledButton
              size="tiny"
              flex={true}
              onClick={() => {
                window.rudderanalytics.track(
                  "Match History - Go To Match History",
                  {
                    isWidget: true,
                  },
                );
                history.push(URLS.MATCH_HISTORY);
              }}
            >
              Go to Match History
            </StyledButton>
          </WantToCreateMoreContainer>
          <StyledImage
            src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/matchHistory/match_history.webp`}
          />
        </GoToMatchHistoryContainer>
      </WidgetContainer>{" "}
      {showDivider && <Divider />}
    </>
  );
};
const WantToCreateMoreContainer = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  align-items: start;
  max-width: 300px;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    align-items: center;
    justify-content: center;
    max-width: unset;
  }
`;
const GoToMatchHistoryContainer = styled(Flex)`
  align-items: center;
  justify-content: space-evenly;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    flex-direction: column-reverse;
    justify-content: start;

    gap: 20px;
  }
`;

const StyledImage = styled(Image)`
  width: 100%;
  max-width: 400px;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.4);
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    max-width: unset;
  }
`;
const StyledBody = styled(Body)`
  max-width: 540px;
  text-align: start;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    text-align: center;
  }
`;

const StyledTitle3 = styled(Title3)`
  font-weight: 600;
  @media (max-width: ${(props) => props.theme.breaks.medium}) {
    margin-top: 0px;
  }
`;
const StyledButton = styled(Button)`
  margin-top: 20px;
  border-radius: 24px;
  padding: 0px 20px;
  height: 30px;
`;

const Logo = styled(HistoryLogo)`
  fill: ${(props) => props.theme.colors.envy};
  width: 36px;
  height: 36px;
`;

const WidgetContainer = styled(Flex)`
  flex-direction: column;
  padding: 24px;
  gap: 24px;
  width: 100%;
  border-color: #233438;
  border-style: solid;
  border-width: 1px;
  box-shadow: 0 0 30px #233438;
  mix-blend-mode: normal;
  background-color: ${({ theme }) => theme.colors.darkerNed};
  border-radius: 16px;
`;

const WidgetHeader = styled(Flex)`
  align-items: start;
  gap: 16px;
  flex-direction: column;
`;

const WidgetTitleContainer = styled(Flex)`
  flex-direction: row;
  gap: 16px;
`;
