import { gql } from "@apollo/client";

const MOMENT_CLIP_FIELDS = gql`
  fragment MomentClipFields on Clips {
    _id
    clipImageThumb
    clipLink
    clipTitle
    shareId
    status
    userClipTitle
    username
  }
`;

export const ROLL_SHARE_CODES = gql`
  query RollShareCodes($simulate: ESimulateOptions) {
    rollShareCodes(simulate: $simulate) {
      status
    }
  }
`;

export const REFRESH_FACEIT = gql`
  mutation RefreshFaceit {
    getFaceitHistory
  }
`;

export const GET_MATCH = gql`
  query Query($csgoMatchId: String!) {
    requestParsing(csgoMatchId: $csgoMatchId)
  }
`;

export const CHECK_FOR_CLIP = gql`
  query CheckClipCreated(
    $steamId: String
    $round: Int
    $replayLinkStore: String
  ) {
    checkClipCreated(
      steamId: $steamId
      round: $round
      replayLinkStore: $replayLinkStore
    ) {
      inTransit
      hasClip
      clip {
        status
        username
        clipLink
        clipImageThumb
        clipTitle
        userClipTitle
        shareId
      }
    }
  }
`;

export const STEAM_FRIENDS = gql`
  query SteamFriends {
    steamFriends {
      avatarUrl
      displayName
      steamId64
      user
    }
  }
`;

export const MATCH_HISTORY = gql`
  ${MOMENT_CLIP_FIELDS}
  query MatchHistory(
    $page: Int
    $steamId: String
    $excludeMomentsWithClips: Boolean
    $limit: Int
    $excludePreviews: Boolean
    $momentsSort: CSMomentsSort
    $momentsLimit: Int
    $skipSteamApi: Boolean
  ) {
    matchHistory(
      page: $page
      steamId: $steamId
      excludeMomentsWithClips: $excludeMomentsWithClips
      limit: $limit
      excludePreviews: $excludePreviews
      momentsSort: $momentsSort
      momentsLimit: $momentsLimit
      skipSteamApi: $skipSteamApi
    ) {
      player {
        avatar
        name
        steamId
      }
      moments {
        game
        map
        platform
        replayLinkStore
        csgoMatchId
        date
        status
        team
        players {
          avatar
          name
          steamId
        }
        demo {
          platform
          source
        }
        potg {
          round
          hasClip
          clip {
            ...MomentClipFields
          }
        }
        result {
          win
          tie
          score
        }
        kills
        deaths
        matchMontages {
          clip {
            _id
            clipImageThumb
            clipLink
            clipTitle
            createdDate
            id
            status
            trackId
          }
          type
        }
        matchMoments {
          round
          reason
          winner
          kills
          side
          deaths
          weapons
          events
          hasClip
          clipTitle
          players {
            avatar
            name
            steamId
          }
          clip {
            ...MomentClipFields
          }
        }
      }
      matchCount
    }
  }
`;

export const MATCH_HISTORY_LEAGUE_TABLE = gql`
  ${MOMENT_CLIP_FIELDS}
  query MatchHistoryLeague($puuid: String, $page: Int, $limit: Int) {
    leagueMatchHistory(puuid: $puuid, page: $page, limit: $limit) {
      matchCount
      player {
        profileIconId
        puuid
        riotId
      }
      matches {
        computedRole
        date
        win
        matchDuration
        gameMode
        gameType
        champion
        kills
        deaths
        assists
        matchId
        enemyTeam {
          champion
          championId
          puuid
          riotId
        }
        friendlyTeam {
          champion
          championId
          puuid
          riotId
        }
        matchMoments {
          gameClock
          clipTitle
          clipRequested
          clip {
            ...MomentClipFields
          }
          potg
          victims {
            champion
          }
          monsterKills {
            type
          }
        }
        opponent {
          champion
        }
      }
    }
  }
`;
