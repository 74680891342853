import { themeTwo } from "./themeTwo";
import merge from "lodash.merge";

const themeThree = {
  text: {
    footnote: {
      fontSize: ".725em",
      fontWeight: 400,
      lineHeight: "1.8em",
    },
    subheader: {
      fontSize: "0.875em",
      fontWeight: 500,
      lineHeight: "1.4285em",
    },
    body: {
      fontSize: "1em",
      fontWeight: 400,
      lineHeight: "1.375em",
    },
    headline: {
      fontSize: "1.125em",
      fontWeight: 500,
      lineHeight: "1.222em",
    },
    title3: {
      fontSize: "1.25em",
      fontWeight: 500,
      lineHeight: "1.25em",
    },
    title2: {
      fontSize: "1.375em",
      fontWeight: 400,
      lineHeight: "1.272em",
    },
    title1: {
      fontSize: "1.75em",
      fontWeight: 400,
      lineHeight: "1.214em",
    },
    large3: {
      fontSize: "2.125em",
      fontWeight: 500,
      lineHeight: "1.205em",
    },
    large3bold: {
      fontSize: "2.125em",
      fontWeight: 700,
      lineHeight: "1.458em",
    },
    large2: {
      fontSize: "3em",
      fontWeight: 400,
      lineHeight: "1.866em",
    },
    large1: {
      fontSize: "3.75em",
      fontWeight: 400,
      lineHeight: "1.866em",
    },
    large1Bold: {
      fontSize: "3.75em",
      fontWeight: 700,
      lineHeight: "1.866em",
    },

    //new font sizes
    paragraphSmall: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "20px",
    },
    paragraphMedium: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "24px",
    },
    paragraphLarge: {
      fontSize: "18px",
      fontStyle: "normal",
      fontWeight: "400",
      lineHeight: "28px",
    },
    labelXSmall: {
      fontSize: "12px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "16px",
    },
    labelXXSmall: {
      fontSize: "10px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "12px",
    },
    labelSmall: {
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "16px",
    },

    labelMedium: {
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
    },
    titleSmall: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "36px",
    },
    headingXLarge: {
      fontSize: "36px",
      fontStyle: "normal",
      fontWeight: "700",
      lineHeight: "44px",
    },
    headingMedium: {
      fontSize: "28px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "30px",
    },
    headingSmall: {
      fontSize: "24px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "30px",
    },
    headingXSmall: {
      fontSize: "20px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "30px",
    },
    displayMedium: {
      fontSize: "52px",
      fontStyle: "normal",
      fontWeight: "600",
      lineHeight: "64px",
    },
  },
};
const theme = merge(themeTwo, themeThree);
export default theme;
