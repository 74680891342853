import { useState, useEffect } from "react";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useUser } from "../../Hooks/useUser";
import {
  IsOwnMatchHistory,
  RequestedClips,
  RequestedMontages,
  type TClipsRequested,
  type TMontageRequest,
} from "../../State/matchHistory";
import {
  CHECK_FOR_CLIP,
  MATCH_HISTORY,
  REFRESH_FACEIT,
  ROLL_SHARE_CODES,
  STEAM_FRIENDS,
} from "../../GraphQL/matchHistory";
import { GET_MY_SIGNATURE } from "../../GraphQL/loadoutSelector";
import { getQueryParam } from "../../Utilities/QueryParam";
import { MatchHistoryViewModel } from "../MatchHistory/ViewModel";
import { useSetRecoilState } from "recoil";
import { getPlayerSearchIdentifier } from "../MatchHistory/utils";

interface ICS2MatchHistoryViewModel {
  useWidgetQuery?: boolean;
  cache?: boolean;
}
export const CS2MatchHistoryViewModel = ({
  useWidgetQuery = false,
}: ICS2MatchHistoryViewModel = {}) => {
  const playerSearchIdentifier = getPlayerSearchIdentifier();
  const [endOfList] = useState(false);
  const [noMatches] = useState(false);
  const page = getQueryParam("page") || "1";
  const limit = useWidgetQuery ? 2 : 10;
  const { hasSteamLinked } = MatchHistoryViewModel();
  const { allstarUser } = useUser();
  const setRequestedMontages = useSetRecoilState(RequestedMontages);
  const setRequestedClips = useSetRecoilState(RequestedClips);
  const setIsOwnMatchHistory = useSetRecoilState(IsOwnMatchHistory);
  const {
    data: matchesData,
    error: matchesError,
    loading: matchesLoading,
  } = useQuery(MATCH_HISTORY, {
    variables: {
      limit,
      page: Number.parseInt(page),
      steamId: playerSearchIdentifier || allstarUser?.user?.steam?.steamid,
      excludeMomentsWithClips: useWidgetQuery,
      excludePreviews: useWidgetQuery,
      momentsSort: useWidgetQuery ? "SCORE" : "ROUND",
      momentsLimit: useWidgetQuery ? 2 : undefined,
      skipSteamApi: !!useWidgetQuery,
    },
    skip: !playerSearchIdentifier && !hasSteamLinked,
    fetchPolicy: "cache-and-network",
    errorPolicy: "all",
  });

  const { data: signatureData } = useQuery(GET_MY_SIGNATURE, {
    fetchPolicy: "cache-and-network",
    skip: !allstarUser.loggedIn,
  });

  const [getClip] = useLazyQuery(CHECK_FOR_CLIP, {
    fetchPolicy: "network-only",
  });

  const [rollShareCodes, { loading: rollShareCodesLoading }] = useLazyQuery(
    ROLL_SHARE_CODES,
    { fetchPolicy: "network-only" },
  );

  const [noSteamError, setNoSteamError] = useState(false);

  useEffect(() => {
    if (!matchesData?.matchHistory?.moments?.length) return;
    setIsOwnMatchHistory(!playerSearchIdentifier);
  }, [
    playerSearchIdentifier,
    matchesData?.matchHistory?.moments?.length,
    setIsOwnMatchHistory,
  ]);

  useEffect(() => {
    if (!matchesData) return;
    const { moments: matches } = matchesData.matchHistory;
    if (!matches.length) return;
    const processingMontages = [] as TMontageRequest[];
    const processingClips = [] as TClipsRequested[];
    for (const match of matches) {
      for (const montage of match.matchMontages) {
        const clip = montage?.clip;
        if (["Queued", "Submitted"].includes(clip?.status)) {
          processingMontages.push({
            matchId: match.csgoMatchId,
            ts: Date.now(),
            type: montage.type,
            steamIds: [],
            trackId: clip?.requestId,
          });
        }
      }

      for (const moment of match.matchMoments) {
        const clip = moment?.clip;
        if (["Queued", "Submitted"].includes(clip?.status)) {
          processingClips.push({
            matchId: match.csgoMatchId,
            ts: Date.now(),
            round: moment.round,
            steamId: "",
          });
        }
      }
    }
    setRequestedClips(processingClips);
    setRequestedMontages(processingMontages);
  }, [matchesData, setRequestedMontages, setRequestedClips]);

  useEffect(() => {
    if (!allstarUser?.user?.steam?.steamid) {
      return setNoSteamError(true);
    }
  }, [allstarUser]);

  const setRequestedMontagesState = async (request: TMontageRequest) => {
    setRequestedMontages((prev) => {
      return [...prev, request];
    });
  };

  return {
    endOfList,
    fetch,
    getClip,

    matchesData,
    signatureData,
    matchesLoading,
    matchesError,
    noMatches,
    noSteamError,
    page,

    setRequestedMontagesState,
    rollShareCodes,
    rollShareCodesLoading,
    limit,
  };
};

export const SteamFriendsViewModel = () => {
  const { hasSteamLinked } = MatchHistoryViewModel();
  const { data: friendsData, loading: friendsLoading } = useQuery(
    STEAM_FRIENDS,
    {
      skip: !hasSteamLinked,
      fetchPolicy: "cache-and-network",
    },
  );
  return {
    friendsData,
    friendsLoading,
  };
};
