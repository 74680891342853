import { useState } from "react";
import { Flex, Text } from "rebass/styled-components";
import styled from "styled-components";
import { MdOutlineTimer } from "react-icons/md";
import { Divider } from "../../../../Components/Divider";
import { ReactComponent as RemixIcon } from "../../../../../assets/images/badges/remix-icon.svg";
import { Button } from "../../../../Components/Button";
import { ClipThumbnail } from "../ClipThumbnail";
import { Modal } from "../../../../Components/Wrappers";
import { RemixModal } from "../../../../Components/Modals/RemixSurpriseOrStudio";
import { useUser } from "../../../../Hooks/useUser";
import { useHistory } from "react-router-dom";
import { MomentTags } from "../../../MatchHistory/MomentTags";

const MomentClip = ({
  clip,
  round,
  side,
  tags,
  isPOTG,
}: {
  clip: TClip;
  round: number;
  side: string;
  tags: string[];
  isPOTG: boolean;
}) => {
  const [isRemixModalOpen, setRemixModalOpen] = useState(false);
  const { allstarUser } = useUser();
  const history = useHistory();
  return (
    <>
      <Modal
        isModalOpen={isRemixModalOpen}
        setModalOpen={setRemixModalOpen}
        disableOutsideClose={undefined}
        handleModalClose={undefined}
      >
        <RemixModal
          clip={clip}
          closeModal={() => setRemixModalOpen(false)}
          onSuccess={undefined}
          onRemixError={() => setRemixModalOpen(false)}
        />
      </Modal>
      <Divider />
      <Flex
        justifyContent={"space-between"}
        flexDirection={["column", "column", "row"]}
        alignItems={"center"}
        style={{ gap: 20 }}
      >
        <ClipThumbnail clip={clip} />

        <Flex
          flexDirection={"column"}
          alignContent={"space-between"}
          width="100%"
          style={{ gap: 8 }}
        >
          <Flex
            flexDirection={["column", "row"]}
            justifyContent={"space-between"}
            width="100%"
          >
            <Flex flexDirection={"column"} style={{ gap: 8 }}>
              <StyledClipTitle
                variant={"text.labelSmall"}
                onClick={() => history.push(`/clip?clip=${clip._id}`)}
              >
                {clip.clipTitle}
              </StyledClipTitle>
              <Flex
                justifyContent="flex-start"
                style={{ gap: 4 }}
                flexGrow="1"
                alignItems={"center"}
              >
                <StyledMdOutlineTimer />
                <Text variant={"text.labelXSmall"} color="chalk">
                  Round {round} : {side} Side
                </Text>
              </Flex>
            </Flex>
            <RemixButton
              size="tiny"
              classification="roundedFilled"
              flex={true}
              onClick={() => {
                setRemixModalOpen(true);
                window.rudderanalytics.track(
                  "Match History League - Remix Button Hit",
                  {
                    userGroup: allstarUser?.user?.userGroup?.type,
                  },
                );
              }}
            >
              <StyledRemixIcon />
              Remix
            </RemixButton>
          </Flex>
          <MomentTags tags={tags} isPOTG={isPOTG} clipId={clip._id} />
        </Flex>
      </Flex>
    </>
  );
};

const StyledClipTitle = styled(Text)`
  transition: color 0.2s;
  cursor: pointer;
  &:hover {
    color: ${({ theme }) => theme.colors.envy};
  }
`;

const StyledMdOutlineTimer = styled(MdOutlineTimer)`
  color: ${({ theme }) => theme.colors.envy};
  height: 15px;
  width: 15px;
  margin-top: 1px;
`;
const RemixButton = styled(Button)`
  background-color: ${({ theme }) => theme.colors.midnight};
  background-image: linear-gradient(
    225deg,
    rgba(249, 83, 112, 0.4) 0%,
    rgba(114, 67, 154, 0.4) 100%
  );
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 700;
  padding: 16px;

  :hover {
    background-color: ${({ theme }) => theme.colors.darkerNed};
  }
`;

const StyledRemixIcon = styled(RemixIcon)`
  height: 20px;
  margin-right: 6px;
  width: 20px;
`;

export { MomentClip };
