import { Box, Flex, Text } from "rebass/styled-components";
import styled from "styled-components";

import { Moment } from "../Moment";
import { TimePill } from "../styledComponents";
import { IMatchMoment, TMatchHistory } from "../../@types";

const MomentsList = ({
  match,
  isWidget = false,
  player,
}: {
  match: TMatchHistory;
  isWidget?: boolean;
  player: { name: string; avatar: string; steamId: string };
}) => {
  return (
    <>
      {match.matchMoments.map((moment: IMatchMoment) => (
        <MomentContainer key={moment.round}>
          <Timeline mr="4" className="timeline">
            <TimePill>
              <Text fontSize="0" style={{ minWidth: "auto" }}>
                {moment.round}:{moment.side}
              </Text>
            </TimePill>
          </Timeline>
          <Moment
            moment={moment}
            matchId={match.csgoMatchId}
            isPotg={moment.round === match.potg?.round}
            isWidget={isWidget}
            player={player}
          />
        </MomentContainer>
      ))}
    </>
  );
};

const MomentContainer = styled(Flex)`
  :first-child :first-child.timeline {
    margin-top: 28px;
    padding: 0;
  }

  :last-child :first-child.timeline {
    height: 0;
  }
`;

const Timeline = styled(Box)`
  background: linear-gradient(
    90deg,
    #0000 98%,
    ${({ theme }) => theme.colors.navy} 98%
  );
  background-size: 52%;
  min-width: 60px;
  padding-top: 28px;
  width: 60px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    display: none;
  }
`;

export { MomentsList };
