import styled from "styled-components";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import Lottie from "lottie-react";
import { MdOutlineMovieCreation } from "react-icons/md";

import { Card } from "../styledComponents";
import { MomentClip } from "../MomentClip";
import {
  ProcessingButton,
  TinyAvatar,
  TransitTimeWrapper,
} from "../../../MatchHistory/styledComponents";
import Remixing from "../../../../../animations/remixing.json";
import Loading from "../../../../../animations/loading.json";
import { HiOutlineInformationCircle } from "react-icons/hi";
import { Button, Link } from "../../../../Components/Button";

import { TimePill } from "../styledComponents";
import { useState } from "react";
import { useIsMobileApp } from "../../../../Hooks/useIsMobileApp";
import { useUser } from "../../../../Hooks/useUser";
import { IMatchMoment } from "../../@types";
import {
  getCs2OpponentTeamIcon,
  getMyTeamIcon,
} from "../../../MatchHistory/utils";
import { useRecoilValue } from "recoil";
import {
  IsOwnMatchHistory,
  RequestedClips,
} from "../../../../State/matchHistory";
import { getWeaponSvg } from "../../utils";
import { usePortal } from "../../../../Hooks/usePortal";
import { TransitTime } from "../../../../Components/TransitTime";
import { MomentTags } from "../../../MatchHistory/MomentTags";
import { CS2MomentViewModel } from "./ViewModel";
import { ESupportedGames } from "../../../../@types";
import { CreateForFriend } from "../../../MatchHistory/CreateForFriend";
import { SignupToCreate } from "../../../MatchHistory/SignupToCreate";

const SUPPORT_URL = "https://help.allstar.gg/hc/en-us/requests/new";
const { REACT_APP_S3_CLIP_BUCKET } = process.env;
interface IClipCreateError {
  message: string;
  support?: boolean;
}

const Moment = ({
  moment,
  matchId,
  isPotg,
  isWidget,
  player,
}: {
  moment: IMatchMoment;
  matchId: string;
  isPotg: boolean;
  isWidget?: boolean;
  player: { name: string; avatar: string; steamId: string };
}) => {
  const { requestClip } = CS2MomentViewModel();
  const { allstarUser } = useUser();
  const { isMobileApp } = useIsMobileApp();
  const { portalOpen, setPortalOpen, Portal } = usePortal(
    <TransitTimeWrapper>
      <TransitTime user={allstarUser?.user?._id} />
    </TransitTimeWrapper>,
  );
  const isOwnMatchHistory = useRecoilValue(IsOwnMatchHistory);
  const {
    portalOpen: friendPortalOpen,
    setPortalOpen: setFriendPortalOpen,
    Portal: FriendPortal,
  } = usePortal(<CreateForFriend />);
  const {
    portalOpen: signupPortalOpen,
    setPortalOpen: setSignupPortalOpen,
    Portal: SignupPortal,
  } = usePortal(<SignupToCreate />);
  const clipsRequested = useRecoilValue(RequestedClips);
  const [locked, setLocked] = useState(false);
  const [error] = useState<IClipCreateError | null>(null);
  const [processing, setProcessing] = useState(false);

  const numKills = moment.kills;
  const mappableKillsArray = Array(numKills).fill(1);
  const momentHasBeenRequested = clipsRequested
    .map((clip) => clip.round)
    .includes(moment.round);
  const isProcessed = ["Ready", "Processed"].includes(moment?.clip?.status);
  const handleClick = async () => {
    if (!allstarUser.loggedIn) {
      return setSignupPortalOpen(true);
    }
    if (!isOwnMatchHistory) {
      window.rudderanalytics.track("Match History - Create For Friend", {
        type: "clip",
        game: 7302,
      });
      return setFriendPortalOpen(true);
    }
    setLocked(true);
    await requestClip({
      matchId: matchId,
      round: moment.round,
    });

    setProcessing(true);
    setLocked(false);
  };

  return (
    <StyledCard>
      {portalOpen && Portal}
      {friendPortalOpen && FriendPortal}
      {signupPortalOpen && SignupPortal}
      <Flex
        alignItems="center"
        style={{ gap: 24, height: "32px", position: "relative" }}
        flexWrap="nowrap"
      >
        <Flex alignItems="center" style={{ gap: 8 }} flexShrink="0">
          <TinyAvatar
            src={
              !isOwnMatchHistory
                ? player?.avatar || getMyTeamIcon(moment.side)
                : allstarUser?.user?.avatar || getMyTeamIcon(moment.side)
            }
          />

          {moment?.players?.length ? (
            <Flex style={{ gap: 8 }} alignItems="center">
              <StyledWeaponImage
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/cs2_icons/equipment/${getWeaponSvg(
                  moment.weapons[0],
                )}`}
              />
              <Victims>
                {moment.players.map((player, index) => {
                  return (
                    <TinyAvatar
                      key={index}
                      src={player.avatar || getCs2OpponentTeamIcon(moment.side)}
                    />
                  );
                })}
              </Victims>
            </Flex>
          ) : (
            <Flex style={{ gap: 8 }} alignItems="center">
              <StyledWeaponImage
                src={`${REACT_APP_S3_CLIP_BUCKET}/static/web/cs2_icons/equipment/${getWeaponSvg(
                  moment.weapons[0],
                )}`}
              />
              <Victims>
                {mappableKillsArray.map((player, index) => {
                  return (
                    <TinyAvatar
                      key={index}
                      src={getCs2OpponentTeamIcon(moment.side)}
                    />
                  );
                })}
              </Victims>
            </Flex>
          )}
        </Flex>
        <Flex
          style={{
            gap: 8,
            marginRight: "auto",

            alignItems: "start",
          }}
          flexDirection={"column"}
        >
          <Title title={moment.clipTitle} textAlign={"center"}>
            {error && (
              <ClipError>
                <StyledInfo />
                {error.message}
                {error?.support && (
                  <SupportLink href={SUPPORT_URL}>Contact support?</SupportLink>
                )}
              </ClipError>
            )}
            {moment.clipTitle.split("+")[0].trim()}
          </Title>

          {!isProcessed && <MomentTags tags={moment.events} isPOTG={isPotg} />}
        </Flex>
        <Box>
          {isProcessed ? (
            <CreatedText>Clip created!</CreatedText>
          ) : processing || momentHasBeenRequested ? (
            <StyledProcessingButton
              flex={true}
              size="tiny"
              onClick={() => setPortalOpen(true)}
            >
              Clip Pending
              <Lottie
                animationData={Remixing}
                loop={true}
                style={{ height: 36, width: 36, minWidth: 36 }}
              />
            </StyledProcessingButton>
          ) : (
            <ClipButton
              size="tiny"
              classification="roundedFilled"
              flex={true}
              disabled={locked || error}
              onClick={async () => handleClick()}
              onClickCapture={() => {
                window.rudderanalytics.track("Match History - Request Clip", {
                  userGroup: allstarUser?.user?.userGroup?.type,
                  matchId,
                  isMobile: isMobileApp,
                  game: ESupportedGames.CS2,
                  isWidget,
                  isVisitor: !allstarUser.loggedIn,
                  createForFriend: !isOwnMatchHistory && allstarUser?.loggedIn,
                });
              }}
            >
              {locked && !error ? (
                <Lottie
                  animationData={Loading}
                  style={{ height: 24, width: 24 }}
                  loop={true}
                />
              ) : (
                <>
                  <StyledMdOutlineMovieCreation />
                  {isOwnMatchHistory || !allstarUser.loggedIn
                    ? "Clip"
                    : "Clip for Friend"}
                </>
              )}
            </ClipButton>
          )}
        </Box>
      </Flex>
      {isProcessed && (
        <MomentClip
          clip={moment.clip}
          round={moment.round}
          side={moment.side}
          tags={moment.events}
          isPOTG={isPotg}
        />
      )}
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  padding: 24px;
  flex-grow: 1;
  margin-bottom: 16px;

  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding: 24px 12px;
  }
`;

const StyledProcessingButton = styled(ProcessingButton)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
`;

const Victims = styled(Flex)`
  align-items: center;
  gap: 4px;
`;

const StyledInfo = styled(HiOutlineInformationCircle)`
  color: ${({ theme }) => theme.colors.alert};
  height: 24px;
  width: 24px;
`;

const ClipError = styled(Flex)`
  align-items: center;
  bottom: -38px;
  color: ${({ theme }) => theme.colors.alert};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  gap: 4px;
  position: absolute;
  right: 0px;
`;

const Title = styled(Text)`
  font-size: ${({ theme }) => theme.fontSizes[2]};
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

const POTGPill = styled(TimePill)`
  background: ${({ theme }) => theme.colors.navy};
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSizes[1]};
  margin: 6px 0 6px 12px;
  padding: 2px 12px 2px 4px;

  @media (max-width: ${(props) => props.theme.breaks.standard}) {
    background: none;
    border: none;
    margin-left: 0;
    padding: 2px;
  }
`;

const CreatedText = styled(Text)`
  color: ${({ theme }) => theme.colors.envy};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 500;
`;

const ClipButton = styled(Button)`
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: 700;
  padding: 16px;

  :disabled {
    cursor: not-allowed;
  }

  :disabled svg {
    color: ${({ theme }) => theme.colors.chalkAlpha40};
  }
`;

const StyledMdOutlineMovieCreation = styled(MdOutlineMovieCreation)`
  color: ${({ theme }) => theme.colors.ice};
  height: 20px;
  margin-right: 6px;
  min-width: 16px;
  width: 20px;
`;

const SupportLink = styled(Link)`
  color: ${({ theme }) => theme.colors.alert};
  text-decoration: underline;
`;

const StyledWeaponImage = styled(Image)`
  filter: brightness(0) saturate(100%) invert(80%) sepia(7%) saturate(717%)
    hue-rotate(144deg) brightness(82%) contrast(86%);
  flex: 1 1 24px;
  height: 14px;
  max-width: 100%;
`;

export { Moment };
