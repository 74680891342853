import { useState } from "react";
import { createPortal } from "react-dom";
import { Modal } from "../../Components/Modal";

export const usePortal = (content: JSX.Element) => {
  const [portalOpen, setPortalOpen] = useState<boolean>(false);

  const Portal = createPortal(
    <Modal
      isModalOpen={true}
      setModalOpen={() => {
        setPortalOpen(false);
      }}
    >
      {content}
    </Modal>,
    document.getElementById("modal-root"),
  );

  return { portalOpen, setPortalOpen, Portal };
};
