import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { useRecoilState } from "recoil";
import { ReactComponent as PlayButton } from "../../../../Assets/MatchHistory/playButton.svg";
import { AllstarModalState } from "../../../../State/modals";
import { ModalType } from "../../../../@types";

const { REACT_APP_S3_CLIP_BUCKET } = process.env;

const ClipThumbnail = ({ clip }: { clip?: TClip }) => {
  const [, setAllstarModalState] = useRecoilState(AllstarModalState);

  return (
    <Thumbnail
      style={{
        backgroundImage: `url(${REACT_APP_S3_CLIP_BUCKET}/${clip?.clipImageThumb})`,
      }}
      onClickCapture={() => {
        window.rudderanalytics.track("Match History - Watch Clip", {
          game: clip?.game,
        });
        setAllstarModalState({
          isOpen: ModalType.SimpleClipViewer,
          data: { ...clip, viewSource: "match-history-moment" },
          functions: {},
        });
      }}
    >
      <StyledPlayButton />
    </Thumbnail>
  );
};

const Thumbnail = styled(Box)`
  background-size: cover;
  cursor: pointer;
  height: 108px;
  position: relative;
  width: 192px;
  border-radius: 8px;
  flex-shrink: 0;
  @media (max-width: ${({ theme }) => theme.breaks.small}) {
    flex-grow: 1;
    width: 100%;
    aspect-ratio: 16/9;
    height: inherit;
  }

  :hover :first-child {
    opacity: 0.9;
  }
`;

const StyledPlayButton = styled(PlayButton)`
  height: 42px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 42px;
`;

export { ClipThumbnail };
