import React, { lazy } from "react";
import { Router, Switch, Route } from "react-router-dom";
import history from "./history";
import Navigation from "./navigation";
import { ThemeProvider } from "styled-components";
import theme from "./theme/themeThree";
import { RecoilRoot } from "recoil";
import { useGateValue } from "@statsig/react-bindings";

import { UserStatePreFill } from "./MVVM/Hooks/userStatePreFill";
import { GlobalStyles } from "./theme/globalStyles";
import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";

const { REACT_APP_GQL_ENDPOINT, REACT_APP_GQL_ENDPOINT_FEDERATED } =
  process.env;

const LazyIframe = lazy(() =>
  import("./MVVM/Views/Iframe" /* webpackChunkName: 'iframe' */),
);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      /*
      TODO:
      Add logzio logging
      */
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      ),
    );
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      authorization: token ? `Bearer ${token}` : "",
      ...headers,
    },
  };
});

export default function () {
  const federated = useGateValue("federated_graphql");

  const httpLink = createHttpLink({
    uri: federated ? REACT_APP_GQL_ENDPOINT_FEDERATED : REACT_APP_GQL_ENDPOINT,
  });

  const apolloClient = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Promotions: {
          keyFields: ["name"],
        },
      },
    }),
    link: authLink.concat(errorLink).concat(httpLink),
  });

  return (
    <ApolloProvider client={apolloClient}>
      <RecoilRoot id="root">
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <Router history={history}>
            <UserStatePreFill force={false} />
            <Switch>
              <Route exact path="/iframe" component={LazyIframe} />
              <Navigation />
            </Switch>
          </Router>
        </ThemeProvider>
      </RecoilRoot>
    </ApolloProvider>
  );
}
