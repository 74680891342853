import { Flex } from "rebass/styled-components";
import styled from "styled-components";
import { ReactComponent as POTGIcon } from "../../../Assets/MatchHistory/POTG.svg";
import { useHistory } from "react-router-dom";
export const MomentTags = ({
  tags,
  isPOTG,
  clipId,
}: {
  tags: string[];
  isPOTG: boolean;
  clipId?: string;
}) => {
  const firstTwoTags = tags && tags?.length && tags.slice(0, 2);
  const extraTags = firstTwoTags && tags.length - 2;
  const history = useHistory();

  return (
    <>
      <Flex style={{ gap: 8 }}>
        {isPOTG && (
          <StyledPill variant="text.labelXSmall">
            <StyledPOTGIcon />
            POTG
          </StyledPill>
        )}
        {!!firstTwoTags &&
          firstTwoTags.map((tag, index) => (
            <StyledPill key={index} variant="text.labelXSmall">
              {tag}
            </StyledPill>
          ))}
        {extraTags > 0 && clipId && (
          <ClickablePill
            variant="text.labelXSmall"
            onClick={() => {
              history.push(`/clip?clip=${clipId}`);
            }}
          >
            +{extraTags}
          </ClickablePill>
        )}
        {extraTags > 0 && !clipId && (
          <StyledPill
            variant="text.labelXSmall"
            onClick={() => {
              history.push(`/clip?clip=${clipId}`);
            }}
          >
            +{extraTags}
          </StyledPill>
        )}
      </Flex>
    </>
  );
};

const StyledPill = styled(Flex)`
  border-radius: 18px;
  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.colors.chalkAlpha20};
  padding: 4px 12px;
  font-weight: 400;
  font-size: 12px;
  @media (max-width: ${(props) => props.theme.breaks.small}) {
    padding: 2px 8px;
    font-size: 10px;
  }
`;
const StyledPOTGIcon = styled(POTGIcon)`
  height: 17px;
  width: 17px;
  margin-left: -2px;
`;

const ClickablePill = styled(StyledPill)`
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.chalkAlpha20};
  }
`;
