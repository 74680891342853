import { useState } from "react";
import { SetterOrUpdater, useRecoilState, useResetRecoilState } from "recoil";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/client";
import { AllstarUser } from "../../State";
import { User, IAuthenticatedUserQuery } from "../../Model/User";
import { FTUE_STATE } from "../../GraphQL/ftue";
import { IAllstarUser } from "../../Views/Setup/Components/@types";
import {
  FTUEGameState,
  FTUEProfileState,
  FTUEState,
  TFlowState,
} from "../../State/ftue";
import { SignatureSelectorState } from "../../State/loadoutSelector";
import { GET_ME, LOGIN, SIGNUP } from "../../GraphQL/user";
import { URLS } from "../../Utilities/Maps";
import { useStatsigUser } from "@statsig/react-bindings";

const BASE_URL: string = `${process.env.REACT_APP_API_URL}`;

export const useUser = (): {
  allstarUser: IAllstarUser; // Record<string, any>;
  setAllstarUser: SetterOrUpdater<any>;
  loading: boolean;
  getFreshProfile: (
    token?: string,
    forceFtueCheck?: boolean,
  ) => Promise<boolean | void>;
  login: (email: string, password: string) => Promise<any>;
  signup: (
    email: string,
    password: string,
    isMobileApp: boolean,
  ) => Promise<any>;

  logout: (logoutReason: string) => void;
  error: string | null;
} => {
  const location = useLocation();
  const history = useHistory();
  // const [ftueRecoilState, setFtueRecoilState] = useRecoilState(FTUEState);
  const userModel = User.getInstance();
  const [allstarUser, setAllstarUser] = useRecoilState(AllstarUser);
  const [ftueRecoilState, setFtueRecoilState] = useRecoilState(FTUEState);
  const [ftueGameState, setFtueRecoilGameState] = useRecoilState(FTUEGameState);

  const resetFtueGameState = useResetRecoilState(FTUEGameState);
  const resetFtueState = useResetRecoilState(FTUEState);
  const resetFtueProfileState = useResetRecoilState(FTUEProfileState);
  const resetSignatureState = useResetRecoilState(SignatureSelectorState);
  const resetUserState = useResetRecoilState(AllstarUser);

  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [ftueState] = useLazyQuery(FTUE_STATE, { fetchPolicy: "network-only" });
  const [getMe] = useLazyQuery<IAuthenticatedUserQuery>(GET_ME, {
    fetchPolicy: "network-only",
  });
  const [loginGQL] = useLazyQuery(LOGIN, {
    fetchPolicy: "network-only",
    errorPolicy: "all",
  });
  const [signupGQL] = useMutation(SIGNUP);
  const { user, updateUserAsync } = useStatsigUser();

  async function getFreshProfile(
    token?: string | null,
    forceFtueCheck?: boolean,
  ) {
    setLoading(true);
    if (token) localStorage.setItem("token", token);
    else token = localStorage.getItem("token");
    try {
      const decoded = await userModel.DecodeToken();
      if (!decoded) {
        logout("MALFORMED TOKEN");
        return;
      }

      const isEscalated = !typeof decoded.escalated === undefined;
      const authenticatedUserQuery = await getMe();
      const userProfile = authenticatedUserQuery?.data?.me;
      // If the request failed:
      // token has expired.

      if (!userProfile) {
        logout("INVALID TOKEN");
        return;
        // return logout();
      }
      let createdDate = new Date(userProfile.user.createdDate);
      if (createdDate.toString() === "Invalid Date") {
        const numberDate = Number(userProfile.user.createdDate);
        createdDate = new Date(numberDate);
      }

      const stateData = {
        _id: userProfile.user._id,
        admin: userProfile.user.admin,
        avatar: userProfile.user.avatar,
        credits: userProfile.user.credits,
        discord: userProfile.user.discord,
        discordSignUp: userProfile.user.discordSignUp,
        faceit: userProfile.user.faceit,
        google: userProfile.user.google,
        hasClips: false,
        hasSharecodes: userProfile.metadata.hasSharecodes,
        hasUsername: userProfile.profile?.username ? true : false,
        noEmailSet: userProfile.metadata.noEmailSet,
        profile: userProfile.profile,
        rank: userProfile.user.rank,
        riot: userProfile.user.riot,
        steam: userProfile.user.steam,
        tiktok: userProfile.user.tiktok,
        epic: userProfile.user.epic,
        userGroup: userProfile.user.userGroup,
        userScopes: userProfile.user.scope,
        stats: userProfile.stats,
        createdDate: createdDate,
      };

      window.rudderanalytics.identify(decoded?._id, {
        email: decoded.sub,
        userGroup: stateData?.userGroup?.type,
        username: stateData?.profile?.username,
      });

      setAllstarUser({
        escalated: isEscalated,
        loggedIn: true,
        isAdmin: decoded.admin,
        token,
        user: stateData,
        decoded: decoded,
        BASE_URL,
        myAvatar: stateData.avatar,
      });

      const checkFtueState =
        location.pathname.includes("/register") ||
        location.pathname.includes("/login") ||
        forceFtueCheck;
      const next = localStorage.getItem("next");

      if (checkFtueState) {
        try {
          const { data: ftue } = (await ftueState()) as {
            data: {
              ftue: {
                hasCompleted: boolean;
                step: TFlowState;
                stepHistory: TFlowState[];
                gameSelection: string[];
              };
            };
          };

          if (ftue.ftue.hasCompleted === false) {
            setFtueRecoilState({
              ...ftueRecoilState,
              step: ftue.ftue.step ?? "profile",
              stepHistory: ftue.ftue.stepHistory ?? [],
              stateLoaded: true,
            });

            setFtueRecoilGameState((prevState) => {
              return {
                ...prevState,
                gameSelection: prevState.gameSelection.map((game) => {
                  return {
                    ...game,
                    selected: ftue?.ftue?.gameSelection?.includes(game.id),
                  };
                }),
              };
            });

            if (!location.pathname.includes("setup")) {
              return history.push("/setup");
            }
          }

          localStorage.removeItem("next");
          if (next) {
            return history.push(`${next}`);
          }
          return history.push(`/${URLS.DASHBOARD}`);
        } catch (error) {}
      }
      localStorage.removeItem("next");
      if (next) {
        return history.push(`${next}`);
      }
      setLoading(false);
      return true;
    } catch (error: any) {
      setError(error);
      setLoading(false);
    }
  }

  const login = async (email: string, password: string) => {
    const data = await loginGQL({
      variables: {
        email,
        password,
      },
    });

    const token = data?.data?.authentication;

    if (token) {
      localStorage.setItem("token", token);
      window.rudderanalytics.track("Login", {
        loginType: "email",
      });
      window.rudderanalytics.track("Login - New User Redirected");
      const decoded = await userModel.DecodeToken();

      await updateUserAsync({ ...user, email, userID: decoded?._id });
      await getFreshProfile();

      return { status: "SUCCESS" };
    }

    if (data.error)
      return { status: "FAILED", message: "Invalid email or password." };

    return { status: "FAILED", message: "Something went wrong." };
  };

  const signup = async (email: string, password: string) => {
    const signupData = await signupGQL({
      variables: {
        email,
        password,
      },
    });

    if (signupData?.data?.signup?.errors?.length) {
      return { status: "FAILED", message: "Email already exists." };
    }

    const token = signupData?.data?.signup?.token;
    localStorage.setItem("token", token);
    if (token) {
      window.rudderanalytics.track("Signup", {
        type: "email",
      });
      const decoded = await userModel.DecodeToken();
      await updateUserAsync({ ...user, email, userID: decoded?._id });
      await getFreshProfile(token);
      return { status: "", message: "" };
    }

    return { status: "FAILED", message: "Something went wrong." };
  };

  const EXCLUDED_NEXT_PATHS = ["/404", "/reset/form"];
  const PATHS_REQUIRE_AUTH = [
    "/support",
    "/subscriptions",
    "/connectedaccounts",
    "/accountsettings",
    "/notifications",
    "/dashboard",
  ];

  function logout(logoutReason: string) {
    localStorage.removeItem("payload");
    localStorage.removeItem("token");
    resetFtueGameState();
    resetFtueState();
    resetFtueProfileState();
    resetSignatureState();
    resetUserState();
    window.rudderanalytics.track("Logout", {
      logoutReason,
      id: allstarUser?.user?._id,
    });

    if (!PATHS_REQUIRE_AUTH.includes(window.location.pathname)) return;
    if (EXCLUDED_NEXT_PATHS.includes(window.location.pathname))
      window.location.href = "/login";
    return (window.location.href = `/login?next=${window.location.pathname}`);
  }

  return {
    allstarUser,
    setAllstarUser,
    getFreshProfile,
    loading,
    error,
    login,
    signup,
    logout,
  };
};
