import axios from "axios";
import { useUser } from "../../../../Hooks/useUser";
import { useSetRecoilState } from "recoil";
import { RequestedClips } from "../../../../State/matchHistory";

export const CS2MomentViewModel = () => {
  const { allstarUser } = useUser();
  const setRequestedClips = useSetRecoilState(RequestedClips);

  const requestClip = async ({
    matchId,
    round,
  }: {
    matchId: string;
    round: number;
  }) => {
    const steamId = allstarUser?.user.steam?.steamid || "";
    const clipReq = `${process.env.REACT_APP_AWS_SERVERLESS}/webhook/clip-request`;
    const clipsRequestedState = {
      matchId,
      round,
      steamId,
      ts: Date.now(),
    };

    try {
      await axios.post(`${clipReq}?token=${localStorage.getItem("token")}`, {
        matchId,
        steamId: steamId,
        round,
      });
    } catch (error) {
      return error;
    }
    setRequestedClips((prev) => {
      return [...prev, clipsRequestedState];
    });
    return;
  };

  return {
    requestClip,
  };
};
