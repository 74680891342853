import React, { useEffect, useState } from "react";
import { Box } from "rebass/styled-components";
import styled from "styled-components";
import Lottie from "lottie-react";

import { Processing } from "./Components/Processing";
import { TTtransitTime, TransitTimeViewModel } from "./ViewModel";
import { ESupportedGames } from "../../@types";
import { LoadingSkeleton } from "../Skeloton";
import { ETransitStatusTypes } from "./@types";
import Loading from "../../../animations/loading.json";

type TTransitTimeModalProps = {
  user?: string;
  clip?: string;
  spectating?: Record<string,any>;
  refetchClip?: (isRefetch?: boolean) => void;
};

export const TransitTime = ({
  user,
  clip,
  refetchClip,
  spectating,
}: TTransitTimeModalProps) => {
  const {
    transitData: { myTransitTimes },
    transitError,
    transitLoading,
    refetch,
  } = TransitTimeViewModel({ user, clip, spectating });
  const [pendingDocuments, setPendingDocuments] = useState(true);
  const [pollCount, setPollCount] = useState(0);
  const [pollMax, setPollMax] = useState(4);

  useEffect(() => {
    window.rudderanalytics.page("Transit Time Modal");
  }, []);

  useEffect(() => {
    if (transitLoading) return;

    const pending = myTransitTimes.some(
      //(transitTime) => transitTime.localKey === ETransitStatusTypes.Queued || transitTime.localKey === ETransitStatusTypes.Slow,
      (transitTime) => transitTime.localKey === ETransitStatusTypes.Queued,
    );

    setPendingDocuments(pending);
    if (pending && refetch) setTimeout(() => refetch(), 3000);
  }, [myTransitTimes, transitLoading, refetch, pendingDocuments]);

  /**
   * This will poll for the clip if the transit time is considered good.
   * The poll interval will be 1/4 of the approxTimeToComplete and will poll 4 times.
   */
  useEffect(() => {
    let poll: any;

    if (pollCount >= pollMax) return;
    if ((clip || spectating) && myTransitTimes?.length && refetchClip) {
      const singleClipTransitInfo = myTransitTimes[0];
      if (singleClipTransitInfo.localKey === ETransitStatusTypes.Good || singleClipTransitInfo.localKey === ETransitStatusTypes.Slow) {
        if (pollCount == 0) {
          // set max poll intervals to total time to wait 
          setPollMax(Math.ceil(Math.abs(singleClipTransitInfo.approxTimeToComplete)/1000/120));
        }
        let pollInterval = Math.floor(Math.abs(singleClipTransitInfo.approxTimeToComplete) / pollMax);
        pollInterval = pollInterval < 30 * 1000 ? 30 * 1000 : pollInterval;
        if (!poll) {
          poll = setInterval(() => {
            refetchClip(true);
            setPollCount((prev) => prev + 1);
          }, pollInterval);
        }
      }
    }
    return () => {
      clearInterval(poll);
    };
  }, [myTransitTimes, clip, pollCount, refetchClip]);

  if (pendingDocuments)
    return <ResponsiveLottie animationData={Loading} loop={true} />;

  if (transitLoading)
    return (
      <>
        <ScrollableBox>
          {[1, 1, 1].map(() => {
            return (
              <LoadingSkeleton
                width={"100%"}
                height={"150px"}
                containerClassName="flex-grow-1"
                style={{ borderRadius: "8px", marginBottom: "10px" }}
              />
            );
          })}
        </ScrollableBox>
      </>
    );
  if (transitError) return <div>Error...</div>;
  if (!myTransitTimes || !myTransitTimes.length)
    return <div>No Clips in transit...</div>;
  return (
    <>
      <ScrollableBox>
        {myTransitTimes.map(
          ({
            approxTimeToComplete,
            localKey,
            clip: { game, clipTitle, trackId },
            isMobile,
          }: TTtransitTime) => {
            return (
              <Processing
                approxTimeToComplete={approxTimeToComplete}
                localKey={localKey}
                game={game as ESupportedGames}
                clipTitle={clipTitle}
                trackId={trackId}
                isMobile={isMobile}
                spectating={!!spectating}
              />
            );
          },
        )}
      </ScrollableBox>{" "}
    </>
  );
};

const ResponsiveLottie = styled(Lottie)`
  height: 32px;
  width: 32px;
  margin: 0 auto;
`;

const ScrollableBox = styled(Box)`
  overflow-y: auto;
  max-height: 600px;
  ::-webkit-scrollbar-corner {
    background: ${({ theme }) => theme.colors.ned};
  }
  ::-webkit-scrollbar {
    width: 15px;
  }
  ::-webkit-scrollbar-track {
    background: transparent;
  }
  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.midnightAlpha50};
    border: 5px solid transparent;
    border-radius: 999px;
    background-clip: content-box;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: ${({ theme }) => theme.colors.midnightAlpha90};
  }
`;
