import styled from "styled-components";
import { Box, Flex, Image, Text } from "rebass/styled-components";
import { Swiper } from "swiper/react";

import { Headline } from "../../Components/Text";
import { Large3 } from "../../Components/Text";
import { DividerAlt } from "../../Components/Divider";
import { Button } from "../../Components/Button";
import { Avatar } from "../../Components/Avatar";
import { ClipReactions } from "../../Components/Clip/Components/ClipReactions";

export const StyledFlex = styled(Flex)<{ modalView: boolean | undefined }>`
  margin-left: ${(props) => (props.modalView ? "0px" : "120px")};
  padding: ${(props) => (props.modalView ? "0px" : "20px 80px")};

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    margin-left: 0;
    padding: 20px 40px;
  }
`;

export const Heading = styled(Large3)`
  letter-spacing: -0.01em;
  font-weight: ${({ theme }) => theme.text.large3bold.fontWeight};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  :hover {
    color: ${(props) => (props.onClick ? props.theme.colors.envy : "default")};
  }
`;

export const SubHeading = styled(Heading)`
  margin: 0 0 12px 0;
  font-size: ${({ theme }) => theme.text.title3.fontSize};
`;

export const SubTitle = styled(SubHeading)`
  margin: 0 0 2px 0;
  font-size: ${({ theme }) => theme.text.headline.fontSize};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  :hover {
    color: ${(props) => (props.onClick ? props.theme.colors.envy : "default")};
  }

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    font-size: ${({ theme }) => theme.fontSizes[2]};
  }
`;

export const SubTitleLight = styled(SubTitle)`
  font-weight: ${({ theme }) => theme.text.title3};
`;

export const StyledHeadline = styled(Headline)`
  font-size: ${({ theme }) => theme.text.title2.fontSize};
  font-weight: ${({ theme }) => theme.text.large3.fontWeight};
  cursor: ${(props) => (props.onClick ? "pointer" : "default")};

  :hover {
    color: ${(props) => (props.onClick ? props.theme.colors.envy : "default")};
  }
`;

export const StyledText = styled(Text)`
  font-size: ${({ theme }) => theme.text.subheader.fontSize};
  display: inline;
  color: ${({ theme }) => theme.colors.chalk};
  font-weight: ${({ theme }) => theme.text.headline.fontWeight};
`;

export const StyledUsernameText = styled(Text)`
  color: ${({ theme }) => theme.colors.ice};
  font-size: ${({ theme }) => theme.fontSizes[1]};
  font-weight: ${({ theme }) => theme.text.headline.fontWeight};
`;

export const UserDetailsText = styled(Text)`
  font-size: ${({ theme }) => theme.text.body.fontSize};
  color: ${({ theme }) => theme.colors.chalk};
`;

export const StyledAvatar = styled(Avatar)`
  cursor: pointer;
`;

export const AvatarLine = styled(DividerAlt)`
  width: 100%;
`;

export const MoreButton = styled(Button)`
  height: 32px;
  width: 32px;
  border-radius: 20px;
  background: ${({ theme }) => theme.colors.navy};
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: ${({ theme }) => theme.breaks.narrow}) {
    display: none;
  }

  :hover {
    background: ${({ theme }) => theme.colors.envy};
  }

  &.swiper-button-disabled {
    opacity: 0;
  }
`;

export const ClipPreviewThumb = styled(Image)`
  border-radius: 4px;
  margin-bottom: 12px;
  cursor: pointer;
`;

export const CardPreviewThumb = styled(Image)`
  margin-bottom: 12px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const LinkText = styled(Text)`
  font-size: ${({ theme }) => theme.text.body.fontSize};
  color: ${({ theme }) => theme.colors.ice};
  font-weight: ${({ theme }) => theme.text.headline.fontWeight};
  text-decoration: none;
  border: 0;
  display: block;
  text-wrap: nowrap;
`;

export const StyledSlider = styled(Swiper)`
  width: 100%;
`;

export const CardPreviewContainer = styled(Box)`
  background: ${({ theme }) => theme.colors.midnightAlpha40};
  border-radius: 8px;
  position: relative;
  cursor: pointer;

  :hover .cover {
    display: flex;
    border-radius: 8px;
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 99999;
    background-color: ${({ theme }) => theme.colors.midnightAlpha80};
  }
`;

export const CardCover = styled(Flex)`
  display: none;
  flex-directin: column;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.colors.envy};
  border: 2px solid ${({ theme }) => theme.colors.envy};
`;

export const StyledClipReactions = styled(ClipReactions)`
  width: 80px;
  min-height: 40px;

  @media (max-width: ${({ theme }) => theme.breaks.medium}) {
    width: 65px;
    height: 36px;
  }
`;

export const ClipRemixWrapper = styled(Box)`
  position: absolute;
  top: 0;
  background: ${({ theme }) => theme.colors.ned};

  > div > div {
    a {
      height: 18px;
      width: 18px;
    }
    height: 40px;
    width: 100%;
    background: ${({ theme }) => theme.colors.midnightAlpha50};
    border-radius: 8px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
    font-size: ${({ theme }) => theme.text.subheader.fontSize};
  }
`;
