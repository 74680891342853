import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";

export const Card = styled(Box)`
  background: ${({ theme }) => theme.colors.grey850};
  border-radius: 12px;
  padding: 24px;
`;

export const TimePill = styled(Flex)`
  align-items: center;
  background: ${({ theme }) => theme.colors.grey850};
  border-radius: 20px;
  border: 1px solid ${({ theme }) => theme.colors.navy};
  font-weight: 500;
  justify-content: center;
  padding: 8px;
`;
